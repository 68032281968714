import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import EasySetup from "../SVG-assets/EasySetup";
import SmartTicketing from "../SVG-assets/SmartTicketing";
import RealTimeAnalytics from "../SVG-assets/RealTimeAnalytics";
import GetStarted from "./GetStarted";
import "./listConferences.scss";
import HostYourConfSketch from "../icons/HostYourConfSketch";
import ManageCMECredits from "../SVG-assets/ManageCMECredits";

export default function ListConferences() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <div className="list-conf-section">
        <div className="list-conf-grid">
          <div style={{ margin: "auto 0" }}>
            <h1 className="mb-16">
              List your medical or dental Conference, Video Course, Podcast or
              CME in few easy steps!
            </h1>
            <div className="body-regular-gray3 mb-32">
              <p className="mb-4">
                1. Open an account and create a confence, video course, audio
                course, podcast or a cme through organizers dashboard.
              </p>
              <p className="mb-4">
                2. Bring your own banner or user a premade banner to save time.
              </p>
              <p className="mb-4">
                3. Create tickets and publish the conference globally.
              </p>
              <p className="mb-4">
                3. Attendees can book tickets through Confemy itself.
              </p>
              <p className="mb-4">
                5. Track bookings and tickets sale, refunds and cancellation all
                through organizers dashboard.
              </p>
              <p className="mb-4">
                6. Promote your conference on our platform and maximize your
                attendance.
              </p>
              <p className="mb-4">
                7. Upload certificates for attendees through organizers
                dashboard.
              </p>
              <p className="mb-4">
                8. On each ticket sale, a 5% charge is applied. It includes
                local gvt tax and payment processing charge and service charges.
                See refund and cancellation policy for details.
              </p>
              <p className="mb-4">
                9. Free conferences or courses or podcasts, host free without
                any charges.
              </p>
            </div>
            <button
              className="button button-green"
              // onClick={() => navigate("/dashboard/create-conference")}
              onClick={() => navigate("/dashboard/organizer-dashboard")}
            >
              HOST YOUR FIRST EVENT
            </button>
          </div>
          <div style={{ margin: "auto 0" }}>
            <HostYourConfSketch className="list-conf-sketch" />
          </div>
        </div>
      </div>
      <div className="easy-setup-section bg-shade-2">
        <div className="list-conf-grid">
          <div className="easy-setup-text">
            <h1 className="mb-16">Reach wider audience!</h1>
            <div className="body-regular-gray3 mb-32">
              <p className="mb-4">
                Promote your activity to reach wider audience.
              </p>
              <p className="mb-4">
                Explore our email marketing, social media marketing options.
              </p>
              <p className="mb-4">Increase your audience with confemy.</p>
            </div>
          </div>
          <div className="easy-setup-svg-wrap">
            <EasySetup className="easy-setup-svg" />
          </div>
        </div>
      </div>
      <div className="easy-setup-section ">
        <div className="list-conf-grid">
          <div className="smart-ticketing-svg-wrap">
            <SmartTicketing className="smart-ticketing-svg" />
          </div>
          <div className="easy-setup-text-wrap">
            <div>
              <div className="body-regular-gray3 ">
                <h1 className="mb-16">Smart Ticketing</h1>
                <p className="mb-4">
                  Create multiple types of tickets and it's currency.
                </p>
                <p className="mb-4">
                  Publish tickets at desired date and time.
                </p>
                <p className="mb-4">Track how many tickets booked.</p>
                <p className="mb-4">Track how many tickets not booked.</p>
                <p className="mb-4">Cancel a tickets as needed.</p>
                <p className="mb-4">
                  On each ticket sale, a 5% charge is applied. It includes local
                  gvt tax and payment processing charge and service charges. See
                  refund and cancellation policy for details.
                </p>
                <p className="mb-4">
                  Free conferences, host free without any charges.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="easy-setup-section bg-shade-2">
        <div className="list-conf-grid">
          <div className="easy-setup-text">
            <h1 className="mb-16">Real-time Analytics</h1>
            <div className="body-regular-gray3 mb-32">
              <p className="mb-4">Track all bookings.</p>
              <p className="mb-4">See how many people viewed the conference.</p>
              <p className="mb-4">
                Real time analytics on bookings and cancellation and refunds.
              </p>
            </div>
          </div>
          <div className="easy-setup-svg-wrap">
            <RealTimeAnalytics className="easy-setup-svg" />
          </div>
        </div>
      </div>
      <div className="easy-setup-section ">
        <div className="list-conf-grid">
          <div className="smart-ticketing-svg-wrap">
            <ManageCMECredits className="smart-ticketing-svg" />
          </div>
          <div className="easy-setup-text-wrap">
            <div>
              <div className="caption-2-regular-gray3 ">
                <h1 className="mb-16">Upload Certificates</h1>
                <p className="mb-4">
                  Attendees can request credits directly on confemy.
                </p>
                <p className="mb-4">
                  Organizers can send credits and upload certificates directly
                  on confemy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GetStarted />
    </div>
  );
}
