// set side drawer width
export const drawerWidth = 250;

// set drawer background color
export const drawerBackground = "#757575";
export const drawerTextColor = "black";
export const drawerActiveListBackgroundColor = "rgba(255,255,255, 0.08)";

// function to know if menuoption has submenu

export function hasChildren(item) {
  const { items: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}

// set organizers dashboard menu options
// set organizers dasbhaord submenu options and their paths

export const sidemenuOptions = [
  {
    text: "Dashboard",
    // icon: <ODashboardIcon className="icon-size" />,
    path: "/dashboard/organizer-dashboard",
    items: [],
  },
  // {
  //   text: 'Create events',
  //   items: [
  //     {
  //       text: 'Create Conference',
  //       path: '/dashboard/create-conference',
  //     },
  //     {
  //       text: 'Create Video Course',
  //       path: '/dashboard/create-video',
  //     },
  //   ],
  // },

  {
    text: "Conferences",
    // icon: <ODashboardIcon className="icon-size" />,

    items: [
      {
        text: "My Conferences",
        // icon: <ODashboardIcon className="icon-size" />,
        path: "/dashboard/my-conferences",
      },
      {
        text: "Bookings",
        path: "/dashboard/bookings",
      },
      {
        text: "Refunds",
        path: "/dashboard/conference/refunds",
      },
      {
        text: "Credits",
        path: "/dashboard/credit-requests",
      },
    ],
  },

  {
    text: "Video Courses",
    items: [
      {
        text: "My Video Courses",
        path: "/dashboard/my-video-courses",
      },

      {
        text: "Bookings",
        path: "/dashboard/video-course-bookings",
      },
      {
        text: "Refunds",
        path: "/dashboard/videoCourse/refunds",
      },
      {
        text: "Credits",
        path: "/dashboard/videoCourse/credit-requests",
      },
    ],
  },
  {
    text: "Audio Courses",
    items: [
      {
        text: "My Audio Courses",
        path: "/dashboard/my-audio-courses",
      },
      {
        text: "Bookings",
        path: "/dashboard/audio-course-bookings",
      },
      {
        text: "Refunds",
        path: "/dashboard/audioCourse/refunds",
      },
      {
        text: "Credits",
        path: "/dashboard/audioCourse/credit-requests",
      },
    ],
  },

  {
    text: "Podcasts",
    // icon: <ODashboardIcon className="icon-size" />,

    items: [
      {
        text: "My Podcasts",
        // icon: <ODashboardIcon className="icon-size" />,
        path: "/dashboard/my-podcasts",
      },
      {
        text: "Bookings",
        path: "/dashboard/podcast-bookings",
      },
      {
        text: "Refunds",
        path: "/dashboard/podcast/refunds",
      },
    ],
  },

  {
    text: "Earnings",

    items: [
      {
        text: "Conferences",
        path: "/dashboard/conferences/earnings",
      },
      {
        text: "Video Courses",
        path: "/dashboard/videoCourses/earnings",
      },
      {
        text: "Audio Courses",
        path: "/dashboard/audioCourses/earnings",
      },

      {
        text: "Podcast",
        path: "/dashboard/podcast/earnings",
      },
    ],
  },
  {
    text: "Payout",
    // icon: <ODashboardIcon className="icon-size" />,
    path: "/dashboard/payout",
    items: [],
  },

  {
    text: "Organization",
    // icon: <ODashboardIcon className="icon-size" />,
    items: [
      {
        text: "My Organizations",
        path: "/dashboard/my-organizations",
      },

      {
        text: "Create Organization",
        path: "/dashboard/create-organization",
      },
    ],
  },
  {
    text: "Assets",
    // icon: <ODashboardIcon className="icon-size" />,
    items: [
      {
        text: "Credits types",
        path: "/dashboard/confemy-credit-type",
      },
      {
        text: "Speakers",
        path: "/dashboard/user-speakers",
      },
    ],
  },

  {
    text: "Feedback",
    // icon: <ODashboardIcon className="icon-size" />,
    path: "/dashboard/feedback",
    items: [],
  },
];

export const AttendeeSideMenuOptions = [
  {
    text: "Attendee Dashboard",
    // icon: <ODashboardIcon className="icon-size" />,
    path: "/attendee-dashboard/dashboard",
    items: [],
  },

  {
    text: "Conferences",
    // icon: <ODashboardIcon className="icon-size" />,
    items: [
      {
        text: "My Conferences",
        // icon: <ODashboardIcon className="icon-size" />,
        path: "/attendee-dashboard/booked-confs",
      },
      {
        text: "Liked",
        path: "/attendee-dashboard/liked-confs",
      },
      {
        text: "Credits & Certificates",
        path: "/attendee-dashboard/conf-credits",
      },
    ],
  },

  {
    text: "Video Courses",
    items: [
      {
        text: "My Video Courses",
        path: "/attendee-dashboard/video-courses",
      },
      {
        text: "Liked",
        path: "/attendee-dashboard/video-liked",
      },
      {
        text: "Credits & Certificates",
        path: "/attendee-dashboard/video-credits",
      },
    ],
  },

  {
    text: "Audio Courses",
    items: [
      {
        text: "My Audio Courses",
        path: "/attendee-dashboard/my-audio-courses",
      },

      {
        text: "Liked",
        path: "/attendee-dashboard/liked-audios",
      },
      {
        text: "Credits & Certificates",
        path: "/attendee-dashboard/audio-credits",
      },
    ],
  },
  {
    text: "Podcasts",
    items: [
      {
        text: "My Podcasts",
        path: "/attendee-dashboard/my-podcasts",
      },
      {
        text: "Liked",
        path: "/attendee-dashboard/liked-podcasts",
      },
    ],
  },
  {
    text: "Credits",
    // icon: <ODashboardIcon className="icon-size" />,
    items: [
      {
        text: "Total Credits",
        path: "/attendee-dashboard/my-credits",
      },

      {
        text: "External Credits",
        path: "/attendee-dashboard/external-credits",
      },
    ],
  },
  {
    text: "Purchase History",
    // icon: <ODashboardIcon className="icon-size" />,
    path: "/attendee-dashboard/purchase-history",
    items: [],
  },

  {
    text: "Assets",
    // icon: <ODashboardIcon className="icon-size" />,
    items: [
      {
        text: "External Credits Types",
        path: "/attendee-dashboard/external-credit-types",
      },
    ],
  },
  {
    text: "Feedback",
    // icon: <ODashboardIcon className="icon-size" />,
    path: "/attendee-dashboard/feedback",
    items: [],
  },
];
