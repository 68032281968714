import React from "react";
import "./howItWorks.styles.scss";

function HowItWorks() {
  return (
    <section className="how-it-works ">
      <h2 className="how-it-works-heading">What is CONFEMY?</h2>
      <p className="body-regular-gray3 ">
        Organizers can create Medical or Dental Conferences, Video Courses,
        Audio Courses or <br />
        CME activities and publish it on Confemy.
        <br />
        One can Sell tickets for these activities and interested attendees can
        Buy them.
        <br />
        Managing Bookings, Cancelling tickets and Refunding tickets can all be
        done through <br /> Organizers Dashboard.
        <br />
        Attendees can view Video Courses, listen to Podcasts right from the{" "}
        <br />
        Attendee Dashboard.
        <br />
        Attendees can also Track CME Credits and Receive CME Certificates here
        itself.
        <br />
        Keep all your Bookings, Cerfiticates & Credits on CONFEMY for records.
      </p>
    </section>
  );
}

export default HowItWorks;
