import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { loadCreditTypesListAction } from "../../../redux/list/listAction";
import api from "../../../utility/api";
import SubmitCancelButtonWithLoader from "../../button/SubmitCancelButtonWithLoader";
import MaterialTextInput from "../../formik/MaterialTextInput";

const validationSchema = yup.object().shape({
  newCreditType: yup.string().required("Required"),
});
export default function CreditForm({
  setShowCreditForm,
  orgId,
  userId,
  completedStep1,
  host,
}) {
  const dispatch = useDispatch();
  const newConference = useSelector((state) => state.conference.newConference);
  // const orgId = newConference?.hostedBy?.organization?._id;
  // const userId = newConference?.hostedBy?.user?._id;
  // const { completedStep1 } = newConference;

  // submit function
  const onSubmit = async (values, actions) => {
    if (!completedStep1) {
      actions.setFieldError("newCreditType", "Submit step 1 first");
      return;
    }
    let url;
    if (host === "organization") {
      url = `conferences/credits?orgId=${orgId}&type=org`;
    } else {
      url = `conferences/credits?userId=${userId}&type=user`;
    }
    const data = {
      creditDetails: {
        name: values.newCreditType,
      },
    };
    try {
      const response = await api.post(url, data);
      setShowCreditForm(false);
      dispatch(loadCreditTypesListAction(response.data.data.credits));
    } catch (err) {
      actions.setFieldError("newCreditType", err.response?.data.message);
    }
  };
  const initialValues = {
    newCreditType: "",
  };

  const handleCancel = () => {
    setShowCreditForm(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {(props) => {
        return (
          <Form className="form-type-4" autoComplete="off">
            <p className="caption-1-regular-gray3 ml-4 mb-16">
              New credit type will be added to the dropdown
            </p>
            <div className="mb-16">
              <MaterialTextInput
                id="newCreditType"
                type="text"
                name="newCreditType"
                placeholder=" "
                label="Credit name*"
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="off"
              />
            </div>
            <div>
              <SubmitCancelButtonWithLoader
                isSubmitting={props.isSubmitting}
                onCancel={() => handleCancel()}
                fullWidth={true}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
