import Switch from "../../components/switch/Switch";
export default function GuestDetails({ indx, guest, onInputChange }) {
  return (
    <>
      {/* <h4 className="color-primary mb-24"> {guest.name}</h4> */}
      <h4 className="color-primary mb-24">
        Guest {indx + 1} · Ticket {guest?.name}
      </h4>
      <div className="flex-vc mb-16">
        <label htmlFor="isMe" className="caption-2-bold-cblack mr-24">
          Fill my name and email
        </label>
        <Switch
          id="isMe"
          name="isMe"
          // value="isMe"
          value={guest.isMe}
          checked={guest.isMe}
          onChange={(e) => {
            onInputChange(e, guest);
          }}
          disable={false}
        />
      </div>

      <div className="bookingPage2-grid mb-32 ">
        <div className="">
          <div className="form-type-3">
            <input
              required={true}
              type="text"
              placeholder="First name*"
              name="firstName"
              value={guest.firstName}
              onChange={(e) => onInputChange(e, guest)}
            />
          </div>
        </div>
        <div className="">
          <div className="form-type-3">
            <input
              required={true}
              type="text"
              placeholder="Last name*"
              name="lastName"
              value={guest.lastName}
              onChange={(e) => onInputChange(e, guest)}
            />
          </div>
        </div>
        <div style={{ gridColumn: "1/-1" }}>
          <div className="form-type-3">
            <input
              required={true}
              type="email"
              placeholder="Email*"
              name="email"
              value={guest.email}
              onChange={(e) => onInputChange(e, guest)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
