import { useNavigate } from "react-router-dom";
import "../attendee-dashboard/ongoingLearning.scss";
import "../create-video-course/CourseCardWithOptions.scss";
const OdPopularEventCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{ cursor: "pointer" }}
      onClick={() => {
        if (data?.type === "VIDEO") {
          navigate(`/courses/${data?._id}`);
        } else if (data?.type === "AUDIO") {
          navigate(`/courses/${data?._id}`);
        } else if (data?.type === "PODCAST") {
          navigate(`/courses/${data?._id}`);
        } else {
          navigate(`/search-conference/${data?._id}`);
        }
      }}
      className="learningCard mt-16"
    >
      <img
        src={data?.banner?.[0].Location}
        alt="Image"
        className="learningCard-image"
      />
      <div className="learningCard-text">
        {" "}
        <p className="learningCard-tag ">{`${
          data?.type === "VIDEO"
            ? "Video course"
            : data?.type === "AUDIO"
            ? "Audio course"
            : "Conference"
        }`}</p>
      </div>

      <p className="learningCard-title">{data?.title}</p>
      <progress
        value={data?.watchPercentage}
        max="100"
        style={{ backgroundColor: "#08415c" }}
        className="ongoingLearning-progressBar"
      />
    </div>
  );
};

export default OdPopularEventCard;
