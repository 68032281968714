import MaterialTextInput from "../formik/MaterialTextInput";

export default function BankFormUSPart() {
  return (
    <>
      <div className="mb-16">
        <MaterialTextInput
          id="routingNumber"
          type="text"
          label="Routing number*"
          name="routingNumber"
          placeholder=" "
        />
      </div>
    </>
  );
}
