import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import KeyPoints from '../../components/courses-details/KeyPoints.js';
import MediaTag from '../../components/tags/MediaTag.js';
import MediaIncludes from '../../components/courses-details/MediaIncludes.js';
import MediaContent from '../../components/courses-details/MediaContent.js';
import api from '../../utility/api.js';
import { alertAction } from '../../redux/alert/alertAction.js';
import CourseDetailsCard from '../../components/cards/CourseDetailsCard.js';
import {
  getFormattedDateInTz2,
  getPrice,
  likeMedia,
  unlikeConference,
} from '../../utility/commonUtil.js';
import ModalX from '../../components/modal/ModalX.js';
import ReportAbuseForm from '../../components/report-abuse/ReportAbuse.js';
import Loader from '../../components/loader/Loader.js';
import MoreCourses from '../../components/courses-details/MoreCourses.js';
import './videoCourseDetailsPage.scss';
import LikeBlueIcon from '../../components/icons/LikeBlueIcon.js';
import LikeInactiveIcon from '../../components/icons/LikeInactiveIcon.js';
import LikeIcon from '../../components/icons/LikeRedIcon.js';
import { addRecentlyViewed } from '../../utility/commonUtil.js';

export default function VideoCourseDetailsPage() {
  const [isLoading, setIsloading] = useState(true);
  const [media, setMedia] = useState({});
  const [moreMediaByHost, setMoreMediaByHost] = useState([]);
  const [sectionCount, setSectionCount] = useState(0);
  const [episodeCount, setEpisodeCount] = useState(0);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [video, setVideo] = useState({});
  const [previewEpisode, setPreviewEpisode] = useState({});
  const [showReportAbuseForm, setShowReportAbuseForm] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const auth = useSelector((state) => state.auth);
  const { isAuthenticated, user } = auth;

  const mediaId = useParams().mediaId;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const modalHandler = () => {
    setVideo({});
    setShowPreviewModal(false);
  };

  const playPreview = () => {
    setVideo(previewEpisode);
    setShowPreviewModal(true);
  };

  const getMediaDetails = async (Id) => {
    try {
      const response = await api.get(`/media/${Id}`);

      setMedia(response.data.data.media);
      setPreviewEpisode(response.data.data.previewEpisode);
      setMoreMediaByHost(response.data.data.moreCourses);
      setSectionCount(response.data.data.sectionCount);
      setEpisodeCount(response.data.data.episodeCount);
      setIsloading(false);
    } catch (err) {
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  };

  const like = (mediaId, userId, setIsLiked, type) => {
    if (!isLiked && isAuthenticated && user) {
      likeMedia(mediaId, userId, setIsLiked, type);
    } else {
      dispatch(alertAction('Please login to like a media.', 'info'));
    }
  };

  const unlike = (confId, userId, setIsLiked) => {
    if (isLiked && isAuthenticated && user) {
      unlikeConference(confId, userId, setIsLiked);
    } else {
      dispatch(alertAction('Please login to unlike a conference.', 'info'));
    }
  };

  useEffect(() => {
    getMediaDetails(mediaId);
    addRecentlyViewed({ videoCourseId: mediaId });
  }, [mediaId]);

  useEffect(() => {
    setIsLiked(media?.isLiked);
  }, [media?.isLiked]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container pt-64">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="md-top-container ">
            <div className="md-grid">
              <div>
                <div className="mb-12">
                  <MediaTag tag={media?.mediaType} bg={'yellow'} />
                </div>
                <h2
                  className="mb-16"
                  style={{
                    color: '#fff',
                  }}
                >
                  {media?.title}
                </h2>
                <div className="mb-4">
                  <span className="avenir-16-500" style={{ color: '#fff' }}>
                    Created by {'  '}
                  </span>
                  <span className="avenir-16-500" style={{ color: '#fff' }}>
                    {media?.hostedBy?.user
                      ? `${media?.hostedBy?.user?.firstName} ${media?.hostedBy?.user?.lastName}`
                      : media?.hostedBy?.organization?.name}
                  </span>
                </div>
                <div className="avenir-16-500 mb-16" style={{ color: '#fff' }}>
                  <span>Last Updated </span>
                  <span>
                    {getFormattedDateInTz2(media?.updatedAt, 'Asia/Kolkata')}
                  </span>
                </div>
                <div>
                  <p style={{ fontSize: 16 }}>{media?.description}</p>
                </div>
                <div className="media-res-details-con">
                  <div
                    className="flex-vc mt-32 mb-8"
                    style={{
                      width: '100%',
                      height: '100%',
                      fontSize: 20,
                      fontWeight: 700,
                      gap: '8px',
                      wordBreak: 'break-word',
                    }}
                  >
                    {getPrice(
                      media?.currency,
                      media?.actualPrice,
                      media?.price,
                      media?.currencySymbol
                    )}
                  </div>
                  <div className="mb-16">
                    {media?.discount && (
                      <p className="avenir-heavy-16">
                        <span>{media?.discount}</span> % Off
                      </p>
                    )}
                  </div>
                  <div className="flex-vc">
                    <button
                      className="small-button small-button-green mr-10"
                      style={{ flexGrow: 1, height: 54 }}
                      onClick={() => {
                        if (media?.tag === 'Video Course' || 'Audio Course') {
                          navigate(`/book-course/${media?._id}`);
                        } else if (media?.tag === 'Podcast')
                          navigate(`/podcast/booking-price/${media?._id}`);
                        else {
                          navigate(`/book-conference/${media?._id}`);
                        }
                      }}
                    >
                      Book
                    </button>

                    <span
                      className="primary-color-box "
                      style={{ cursor: 'pointer' }}
                    >
                      {isLiked ? (
                        <i
                          className="conference-card-buttons"
                          onClick={() =>
                            unlike(
                              media?._id,
                              user?._id,
                              setIsLiked,
                              media?.type
                            )
                          }
                        >
                          {/* <LikeRedIcon className="icon-sm" /> */}
                          <LikeIcon className="icon-lg" />
                        </i>
                      ) : (
                        <i
                          className="conference-card-buttons"
                          onClick={() =>
                            like(media?._id, user?._id, setIsLiked, media?.type)
                          }
                        >
                          <LikeInactiveIcon className="icon-lg" />
                        </i>
                      )}
                    </span>
                    {/* <LikeInactiveIcon className="icon-size" /> */}
                  </div>
                </div>
              </div>
              <div
                className="media-cr-detail-card"
                style={{
                  width: 320,
                  position: 'relative',
                }}
              >
                <CourseDetailsCard media={media} playPreview={playPreview} />
              </div>
            </div>
          </div>
          <div className="md-bottom-container">
            <div className="md-grid">
              <div>
                <div className="mb-24">
                  <KeyPoints keyPoints={media?.keyPoints} />
                </div>
                <div className="mb-24">
                  <MediaIncludes courseIncludes={media?.courseIncludes} />
                </div>
                <div className="mb-24">
                  <MediaContent
                    data={media}
                    sectionCount={sectionCount}
                    episodeCount={episodeCount}
                  />
                </div>
                <MoreCourses media={media} moreMediaByHost={moreMediaByHost} />
                <div>
                  <button
                    className="small-button-outlined small-button-outlined-primary"
                    onClick={() => setShowReportAbuseForm(true)}
                  >
                    Report abuse
                  </button>
                </div>
              </div>
              <div
                style={{
                  width: 320,
                }}
              >
                {/* need this empty */}
              </div>
            </div>
          </div>
          {showPreviewModal && previewEpisode && (
            <ModalX onDismiss={() => modalHandler()} open={showPreviewModal}>
              <div className="modalxContainerPlayer mb-40">
                <ReactPlayer
                  controls={true}
                  playing={true}
                  url={video?.source?.Location}
                  width="100%"
                  height="100%"
                  config={{
                    file: { attributes: { controlsList: 'nodownload' } },
                  }}
                  loop={true}
                />
              </div>
            </ModalX>
          )}
          {/* {showPreviewModal && previewEpisode && (
        <ModalX onDismiss={() => modalHandler()} open={showPreviewModal}>
          <div className={`${""} mb-40 p-16`}>
            <ReactPlayer
              controls={true}
              playing={true}
              url={video?.source?.Location}
              config={{
                file: { attributes: { controlsList: "nodownload" } },
              }}
              // width="100%"
              // height="100%"
              width="400px"
              height="50px"
              loop={true}
            />
          </div>
        </ModalX>
      )} */}
          {showReportAbuseForm && (
            <ModalX
              onDismiss={() => setShowReportAbuseForm(false)}
              open={showReportAbuseForm}
            >
              <div className="modalx-container-sm mb-40">
                <ReportAbuseForm
                  setShowReportAbuseForm={setShowReportAbuseForm}
                  data={media}
                />
              </div>
            </ModalX>
          )}
        </>
      )}
    </div>
  );
}
