import "./mediaIncludesCard.scss";
import PlusIcon from "../icons/PlusIcon";

const MediaIncludesCard = ({ icon = null, text = "" }) => {
  return (
    <div className="mic-box">
      <i>
        <PlusIcon className="icon-size" />
      </i>
      <p className="avenir-16-500">{text}</p>
    </div>
  );
};

export default MediaIncludesCard;
