import "./dbConfsTable.styles.scss";

export default function DbConfsTable({ confs }) {
  let myconfs = confs?.filter((conf) => {
    return conf?.status !== "Unpublished";
  });

  return (
    <div
      style={{
        boxShadow: "none",
        borderRadius: "6px",
        border: "solid 1px #ecf0f2",
      }}
      className="db-confs-table-wrap"
    >
      <table style={{ boxShadow: "none", backgroundClor: "#fff" }}>
        <thead
          style={{
            borderRadius: "6px",
            backgroundColor: "#fff",
          }}
        >
          <tr>
            <th
              style={{
                // backgroundColor: '#ecf0f2',
                fontSize: "16px",
                color: " #08415c",
                fontWeight: 500,
              }}
            >
              Draft & Published events
            </th>
            <th
              style={{
                // backgroundColor: '#ecf0f2',
                fontSize: "16px",
                color: " #08415c",
                fontWeight: 500,
              }}
            >
              Date
            </th>
            <th
              style={{
                // backgroundColor: '#ecf0f2',
                fontSize: "16px",
                color: " #08415c",
                fontWeight: 500,
              }}
            >
              Bookings
            </th>
            <th
              style={{
                // backgroundColor: '#ecf0f2',
                fontSize: "16px",
                color: " #08415c",
                fontWeight: 500,
              }}
            >
              Total
            </th>
          </tr>
        </thead>
        <tbody>
          {!myconfs?.length > 0 ? (
            <tr>
              <td>You haven't created any conferences! </td>
            </tr>
          ) : (
            myconfs?.map((conf) =>
              conf.status !== "Expired" ? (
                <tr key={conf?._id}>
                  <td>{conf?.title}</td>
                  <td>{conf?.status}</td>
                  <td>{conf?.totalSold ? conf?.totalSold : 0}</td>
                  <td>{conf?.grossPrice ? conf?.grossPrice : 0}</td>
                </tr>
              ) : null
            )
          )}
        </tbody>
      </table>
    </div>
  );
}
