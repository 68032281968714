import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
  alertAction,
  localAlertAction,
} from "../../redux/alert/alertAction.js";
import api from "../../utility/api.js";
import LocalAlert from "../alert/LocalAlert.js";
import SubmitCancelButtonWithLoader from "../button/SubmitCancelButtonWithLoader.js";
import SubmitButtonWithLoader from "../button/SubmitButtonWithLoader.js";
import MaterialTextInput from "../formik/MaterialTextInput.js";
import {
  loadCityList,
  loadCountryList,
  loadStateList,
} from "../../utility/commonUtil.js";
import ReloadableSelectFormType1 from "../reselect/ReloadableSelectFormType1.js";
import TextError from "../formik/TextError.js";
import BankFormPart1 from "./BankFormPart1.js";
import BankFormPart2 from "./BankFormPart2.js";

const bankValidation = yup.object().shape({
  bankName: yup.string().trim().required("Required"),
  country: yup.string().trim().required("Required"),
  state: yup.string().trim().required("Required"),
  city: yup.string().trim().required("Required"),
  address1: yup.string().trim().required("Required"),
  zipcode: yup.string().trim().required("Required"),
});

const accountValidation = yup.object().shape({
  nickName: yup.string().trim().required("Required"),
  nameOnAccount: yup.string().trim().required("Required"),
  accountNumber: yup
    .number("Must be a number type") // Validates for numerical value
    .positive("Must be a positive value") // Validates against negative values
    .required("Please enter a account number. The field cannot be left blank.") // Sets it as a compulsory field
    .min(1, "Your account number must be greater than or equal to 1!"),
  routingNumber: yup.number().when("currency", {
    is: (currency) => currency === "USD",
    then: yup
      .number("Must be a number type")
      .positive("Must be a positive value")
      .required("Required.")
      .min(1, "Your account number must be greater than or equal to 1!"),
  }),
  ifscCode: yup.string().when("currency", {
    is: (currency) => currency === "INR",
    then: yup.string().trim().required("Required"),
  }),
  // upi: yup.string().trim().required('Required'),
});

const addBankValidationSchema = [bankValidation, accountValidation];

const pages = ["Bank Details", "Account Details"];

const AddBankAccountForm = ({
  currency,
  setUserData,
  setShowAddBankAccountForm,
  setTransactionData,
  setTransactionCounts,
}) => {
  const [currentPage, setcurrentPage] = useState(0);
  const validationSchema = addBankValidationSchema[currentPage];
  const isLastpage = currentPage === pages.length - 1;

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const { countryList, stateList, cityList } = useSelector(
    (state) => state.list
  );

  const initialValues = {
    bankName: "",
    address1: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",

    nickName: "",
    nameOnAccount: "",
    currency: currency,
    routingNumber: "",
    accountNumber: "",
    ifscCode: "",
    upi: "",
  };

  const onSubmit = async (values, actions) => {
    // for step 0
    if (currentPage === 0) {
      setcurrentPage(currentPage + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }

    if (isLastpage) {
      const {
        nameOnAccount,
        bankName,
        ifscCode,
        upi,
        accountNumber,
        address1,
        address2,
        country,
        state,
        city,
        zipcode,
        routingNumber,
        nickName,
      } = values;
      const data = {
        userDetails: {
          name: nameOnAccount,
          bankAccount: bankName,
          ifsc: ifscCode,
          upi,
          accountNumber,
          currency,
          address1,
          address2,
          country,
          state,
          city,
          zipcode,
          routingNumber,
          nickName,
        },
      };
      try {
        let response = await api.patch(
          `/earnings/${user?._id}/bankAccount`,
          data
        );
        setUserData(response?.data?.data?.userData);
        setTransactionData(response.data.data.withdrawns);
        setTransactionCounts(response.data.data.allAmountArr);
        setShowAddBankAccountForm(false);
        dispatch(alertAction(response.data.message, "success"));
        actions.setSubmitting(false);
      } catch (err) {
        dispatch(localAlertAction(err.response.data.message, "danger"));
      }
    }
  };
  function backPage() {
    setcurrentPage(currentPage - 1);
  }
  useEffect(() => {
    if (!countryList.length > 0) {
      loadCountryList();
    }
  }, []);

  return (
    <div>
      <h2 className=" text-align-center color-primary mb-32">
        Add Bank Account
      </h2>
      <LocalAlert />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {(props) => {
          const { isSubmitting } = props;
          function renderPageContent(page) {
            switch (page) {
              case 0:
                return <BankFormPart1 formikProps={props} />;
              case 1:
                return <BankFormPart2 currency={currency} />;
              default:
                return <div>Not Found</div>;
            }
          }
          return (
            <Form className="form-type-1" autoComplete="off">
              <div className="mb-24">{renderPageContent(currentPage)}</div>
              <div className={isLastpage ? "grid-col-2" : ""}>
                {isLastpage && (
                  <button
                    type="button"
                    style={{
                      width: "100%",
                    }}
                    className="button button-green"
                    onClick={() => backPage()}
                  >
                    Back
                  </button>
                )}
                <SubmitButtonWithLoader
                  isSubmitting={isSubmitting}
                  text={isLastpage ? "Save" : "Continue"}
                  className="button button-primary"
                  fullWidth={true}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddBankAccountForm;
