import { combineReducers } from "redux";

import alertReducer from "./alert/alertReducer";
import authReducer from "./auth/authReducer";
import userLocationReducer from "./location/userLocationReducer";
import myOrganizationsReducer from "./organization/myOrganizationsReducer";
import organizationReducer from "./organization/organizationReducer";
import conferenceReducer from "./conference/conferenceReducer";
import userProfileReducer from "./user-profile/userProfileReducer";
import listReducer from "./list/listReducer";
import assetReducer from "./asset/assetReducer";
import videoCourseReducer from "./video-course/videoCourseReducer";
import audioCourseReducer from "./audio-course/audioCourseReducer";
import podcastReducer from "./podcast/podcastReducre";
import searchReducer from "./search/searchReducer";
import recentlyViewedReducer from "./recently-viewed/recentlyViewedReducer";

const rootReducer = combineReducers({
  alert: alertReducer,
  auth: authReducer,
  userLocation: userLocationReducer,
  myOrganizations: myOrganizationsReducer,
  organization: organizationReducer,
  conference: conferenceReducer,
  video: videoCourseReducer,
  userProfile: userProfileReducer,
  list: listReducer,
  assets: assetReducer,
  audio: audioCourseReducer,
  podcast: podcastReducer,
  search: searchReducer,
  recentlyViewed: recentlyViewedReducer,
});

export default rootReducer;
