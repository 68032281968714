import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import api from "../../utility/api";
import Loader from "../../components/loader/Loader";
import { alertAction } from "../../redux/alert/alertAction";
import CourseBookingPageCard from "../../components/cards/CourseBookingPageCard";
import CheckoutBox from "./CheckoutBox";

export default function CourseBookingPage() {
  const mediaId = useParams().courseId;
  const [media, setMedia] = useState({});
  const [episodeCount, setEpisodeCount] = useState(null);
  const [sectionCount, setSectionCount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [applyCoupon, setApplyCoupon] = useState(false);

  const [coupon, setCoupon] = useState("");
  const [couponData, setCouponData] = useState({});

  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getMediaDetails = async (id) => {
    try {
      setIsLoading(true);
      let response = await api.get(`/media/${id}`);

      setIsLoading(false);
      setEpisodeCount(response.data.data.episodeCount);
      setSectionCount(response.data.data.sectionCount);
      setMedia(response.data.data.media);
    } catch (err) {
      setIsLoading(false);
      dispatch(alertAction(err.response.data.message, "danger"));
    }
  };

  const applyCouponCode = async () => {
    try {
      let formData = {
        mediaDetails: {
          coupon: coupon,
          userId: user?._id,
        },
      };
      let response = "";

      if (media?.type === "VIDEO") {
        response = await api.post(
          `/courses/${media._id}/videoCourse/coupons`,
          formData
        );
      } else {
        response = await api.post(
          `/audioCourses/${media._id}/coupons`,
          formData
        );
      }

      setCoupon("");
      setEpisodeCount(response.data.data.episodeCount);
      setMedia(response.data.data.media);
      setCouponData(response.data.data.couponData);
      setApplyCoupon(true);
    } catch (err) {
      dispatch(alertAction(err.response.data.message, "danger"));
    }
  };
  const buyCourse = async () => {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let formData = {
      purchaseDetails: {
        userId: user?._id,
        timezone: timezone,
        type: media.type === "VIDEO" ? "VIDEO" : "AUDIO",
        isCouponApplied: false,
        coupon: couponData.code,
      },
    };

    if (applyCoupon) {
      formData.purchaseDetails.isCouponApplied = true;
    }

    let url = `/courses/${media._id}/videoCourse/purchase`;

    if (media.type === "AUDIO") {
      url = `audioCourses/${media._id}/purchase`;
    }
    try {
      setIsLoading(true);
      const response = await api.post(url, formData);

      if (response) {
        if (!response.data.data.coursePurchased) {
          if (response?.data?.data?.paymentIntent?.success) {
            window.location.href =
              response?.data?.data?.paymentIntent?.data?.instrumentResponse?.redirectInfo?.url;
          }
        } else {
          setIsLoading(false);
          navigate("/free-booking-status", {
            state: {
              bookingStatus: response.data.data.coursePurchased,
            },
          });
        }
      }
    } catch (err) {
      setIsLoading(false);
      dispatch(alertAction(err.response.data?.message, "danger"));
    }
  };

  useEffect(() => {
    getMediaDetails(mediaId);
  }, [mediaId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="container pt-64">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container-xl">
          <h1 className="color-primary mb-8">Course Details</h1>
          <div
            className="mb-24"
            style={{
              borderBottom: "1px solid #ced9de",
            }}
          ></div>
          <div className="flex flex-wrap" style={{ gap: 24 }}>
            <div
              style={{
                flexGrow: 1,
              }}
            >
              <CourseBookingPageCard
                media={media}
                episodeCount={episodeCount}
                sectionCount={sectionCount}
              />
            </div>
            <div>
              <h4 className="mb-12">Checkout</h4>
              <CheckoutBox
                media={media}
                applyCoupon={applyCoupon}
                setApplyCoupon={setApplyCoupon}
                setCoupon={setCoupon}
                coupon={coupon}
                getMediaDetails={getMediaDetails}
                couponData={couponData}
                applyCouponCode={applyCouponCode}
                buyCourse={buyCourse}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
