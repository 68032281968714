import MaterialTextInput from '../../components/formik/MaterialTextInput';
import TextInput from '../../components/formik/TextInput';
import DeleteIcon from '../../components/icons/DeleteIcon';
import './checkoutBox.scss';

export default function CheckoutBox({
  podcast,
  media,
  applyCoupon,
  setApplyCoupon,
  setCoupon,
  getMediaDetails,
  couponData,
  applyCouponCode,
  buyCourse,
  coupon,
}) {
  return (
    <div className="checkout-box">
      <div
        className="mb-16"
        style={{
          borderBottom: '1px solid #ced9de',
        }}
      ></div>
      {!podcast && (
        <div>
          <p className="caption-2-regular-gray3 mb-8">
            Price: {media.currency} - {media.actualPrice}
          </p>
          <p className="caption-2-regular-gray3 mb-8">
            Service charge: {media.currency}{' '}
            {(media.actualPrice * (5 / 100)).toFixed(2)}
          </p>
          <p className="caption-1-heavy-cblack mb-16">
            Subtotal: {media.currency} - {''}
            {media.actualPrice + media.actualPrice * (5 / 100)}
          </p>{' '}
        </div>
      )}

      <button
        className="small-button small-button-primary mb-16"
        onClick={() => buyCourse()}
        style={{
          height: 48,
          width: '100%',
        }}
      >
        Pay
      </button>
      <div
        className="mb-16"
        style={{
          borderBottom: '1px solid #ced9de',
        }}
      ></div>
      <h4 className="color-primary mb-16">Promotion</h4>
      <div className="redeem-coupon--codes--ZsK6n snipcss0-5-85-88 mb-16">
        {applyCoupon && (
          <div
            data-purpose="code"
            className="redeem-coupon--code--rtYw8 redeem-coupon--code-in-checkout-pane--jhrEf snipcss0-6-88-89"
          >
            <div className="p-4 redeem-coupon--code-text--ZuJBa snipcss0-7-89-90">
              <ul className=" ul-booking snipcss0-8-90-91 ">
                <li data-purpose="code-text" className="snipcss0-9-91-92">
                  <b className="snipcss0-10-92-93 mr-4"> {couponData?.code} </b>
                  is applied
                </li>
                <li className="snipcss0-9-91-94">Confemy coupon</li>
              </ul>
            </div>

            <button
              type="button"
              className="ud-btn ud-btn-large ud-btn-ghost ud-heading-md ud-btn-icon ud-btn-icon-large redeem-coupon--code-icon-button--nwf7w snipcss0-7-89-95"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                className="ud-icon ud-icon-small ud-icon-color-neutral snipcss0-8-95-96"
              >
                <use xlinkHref="#icon-close" className="snipcss0-9-96-97" />
              </svg>
            </button>
            <button
              onClick={() => {
                setApplyCoupon(false);
                getMediaDetails(media?._id);
              }}
              type="button"
              className="ud-btn ud-btn-large ud-btn-ghost ud-heading-md ud-btn-icon ud-btn-icon-large redeem-coupon--code-icon-button--nwf7w"
            >
              <DeleteIcon />
            </button>
          </div>
        )}
      </div>
      <div className="flex-vc-sb" style={{ gap: 8 }}>
        <div className="form-type-3">
          <input
            type="text"
            value={coupon}
            onChange={(e) => setCoupon(e.target.value)}
          />
        </div>
        <button
          style={{
            height: 48,
            // width: "100%",
          }}
          disabled={coupon ? false : true}
          className="small-button small-button-green"
          onClick={() => applyCouponCode()}
        >
          Apply
        </button>
      </div>
    </div>
  );
}
