import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Keyboard, Mousewheel, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import * as yup from "yup";
import TextError from "../formik/TextError";
import { alertAction } from "../../redux/alert/alertAction";
import { createConferenceAction } from "../../redux/conference/conferenceAction";
import { loadAmenities, loadPremadeBanners } from "../../utility/commonUtil";
import SubmitCancelButtonWithLoader from "../button/SubmitCancelButtonWithLoader";
import AddGreenIcon from "../icons/AddGreenIcon";
import DeleteIcon from "../icons/DeleteIcon";
import MultiImageUploader from "../image-uploader/MultiImageUploader";
import SingleImageUploader from "../image-uploader/SingleImageUploader";
import ModalX from "../modal/ModalX";
import SelectFormType1 from "../reselect/SelectFormType1";
import SearchSpeaker from "../speaker/SearchSpeaker";
import SpeakerForm from "../speaker/SpeakerForm";
import Speakercard from "../speaker/Speakercard";
import api from "../../utility/api";
import CloseIcon from "../icons/CloseIcon";
import Modal from "../modal/Modal";
import "./createConference.styles.scss";

const validationSchema = yup.object().shape({
  banner: yup
    .array()
    // .of(yup.object())
    .min(1, "Required. Add a premade banner or your own banner.")
    .compact(),
  speakers: yup.array().of(yup.object()).min(1, "Required").compact(),
  speaker: yup.string().when("openSpeakerModal", {
    is: true,
    then: yup.string().required("Required"),
  }),
  description: yup
    .string()
    .required("Required")
    .min(50, "Minimum 50 characters needed"),
});

export default function ConfDetails2() {
  const [showSpeakerForm, setShowSpeakerForm] = useState(false);
  const [speakerList, setSpeakerList] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);
  const newConference = useSelector((state) => state.conference.newConference);
  const amenityList = useSelector((state) => state.list.amenityList);
  const premadeBanners = useSelector((state) => state.assets.premadeBanners);
  const { completedStep1 } = newConference;

  const initialValues = {
    banner: [],
    description: "",
    speaker: "",
    speakers: [],
    schedules: "",
    courseOutline: "",
    amenities: [],
    venueImages: [],
    deletedBanner: [],
    deletedVenueImages: [],
    deletedSpeakers: [],
  };

  async function onSubmit(values, actions) {
    if (!newConference?.completedStep1) {
      dispatch(alertAction("Complete step-1 first", "danger"));
      return;
    }
    const {
      banner,
      description,
      speakers,
      schedules,
      courseOutline,
      amenities,
      venueImages,
      deletedBanner,
      deletedVenueImages,
    } = values;

    const formData = {
      conferenceDetails: {
        speakers: speakers,
        conferenceId: newConference?._id,
        banner: banner,
        deletedBanner: deletedBanner,
        description: description,
        schedules: schedules,
        courseOutline: courseOutline,
        venueImages: [],
        amenities: amenities,
        deletedVenueImages: deletedVenueImages,
      },
    };

    //  Submit banner image and add image url to formData object
    if (banner?.length > 0) {
      const imageDataObj = new FormData();
      let oldBanners = [];
      banner.map((img) =>
        !img.Key ? imageDataObj.append("file", img) : oldBanners.push(img)
      );

      if (imageDataObj.has("file")) {
        try {
          const imagesResponse = await api.post("fileUploads", imageDataObj);
          if (imagesResponse) {
            formData.conferenceDetails.banner = [
              ...oldBanners,
              ...imagesResponse.data.data,
            ];
          }
        } catch (err) {
          dispatch(alertAction("Image(s) failed to save", "danger"));
        }
      }
    }

    if (venueImages?.length > 0) {
      const imageDataObj = new FormData();
      let oldVenueImages = [];

      venueImages.map((img) =>
        !img.Key ? imageDataObj.append("file", img) : oldVenueImages.push(img)
      );

      if (imageDataObj.has("file")) {
        try {
          const imagesResponse = await api.post("fileUploads", imageDataObj);
          if (imagesResponse) {
            formData.conferenceDetails.venueImages = [
              ...oldVenueImages,
              ...imagesResponse.data.data,
            ];
          }
        } catch (err) {
          dispatch(alertAction("Image(s) failed to save", "danger"));
        }
      }
    }

    try {
      const response = await api.post("conferences/step3", formData);
      if (response) {
        dispatch(createConferenceAction(response.data.data.conference));
        actions.resetForm({ values: initialValues });
        navigate("/dashboard/create-conf/step-4");
      }
    } catch (err) {
      dispatch(alertAction(err.response.data.message, "danger"));
    }
  }

  const formik = useFormik({
    initialValues: {
      banner: newConference?.banner || [],
      description: newConference?.description || "",
      openSpeakerModal: false,
      speaker: "",
      speakers: newConference?.conferenceSpeakers || [],
      schedules: newConference?.schedules || "",
      courseOutline: newConference?.courseOutline || "",
      amenities: newConference?.conferenceAmenities || [],
      venueImages: newConference?.venueImages || [],
      deletedBanner: [],
      deletedVenueImages: [],
      deletedSpeakers: [],
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  //   load Speakers

  async function loadSpeakers(host, id) {
    const url = `speakers?_id=${id}&type=${host}`;
    try {
      const response = await api.get(url);
      if (response) {
        setSpeakerList(response.data.data.speakers);
      }
    } catch (err) {
      if (err) {
        dispatch(alertAction(err.response.data.message, "danger"));
      }
    }
  }

  //   function to set up texteditor field value
  function setFormikFieldValue(fieldName, fieldValue) {
    formik.setFieldValue(fieldName, fieldValue);
  }

  // set formik field speakers value

  function setFormikSpeakers(newSpeaker) {
    const speakerAlreadyAssigned = formik.values.speakers.find(
      (speaker) => speaker._id === newSpeaker._id
    );

    if (
      speakerAlreadyAssigned &&
      Object.keys(speakerAlreadyAssigned).length > 0
    ) {
      dispatch(alertAction("Speaker is already assigned", "danger"));
    } else {
      formik.setFieldValue("speakers", [...formik.values.speakers, newSpeaker]);
    }
  }

  //  set formik Venue Images
  function setFormikVenueImages(newImages) {
    formik.setFieldValue("venueImages", [
      ...formik.values.venueImages,
      ...newImages,
    ]);
  }

  const removeConfSpeaker = (val) => {
    const speakersLeft = formik.values.speakers.filter(
      (speaker) => speaker.value !== val
    );
    formik.setFieldValue("speakers", speakersLeft);
  };

  function deleteBanner(image) {
    let imagesDeleted = [];
    let imagesLeft = [];

    formik.values.banner.map((item) =>
      item.Location !== image.Location
        ? imagesLeft.push(item)
        : item.Key && item.Location === image.Location
        ? imagesDeleted.push(item)
        : null
    );

    formik.setFieldValue("banner", imagesLeft);
    formik.setFieldValue("deletedBanner", [
      ...formik.values.deletedBanner,
      ...imagesDeleted,
    ]);
  }

  function deletedVenueImage(image) {
    let imagesDeleted = [];
    let imagesLeft = [];

    formik.values.venueImages.map((item) =>
      item.Location !== image.Location
        ? imagesLeft.push(item)
        : item.Key && item.Location === image.Location
        ? imagesDeleted.push(item)
        : null
    );

    formik.setFieldValue("venueImages", imagesLeft);
    formik.setFieldValue("deletedVenueImages", [
      ...formik.values.deletedVenueImages,
      ...imagesDeleted,
    ]);
  }

  const handleOpenSpeakerForm = () => {
    setShowSpeakerForm(true);
    setFormikFieldValue("openSpeakerModal", false);
    formik.setFieldTouched("speaker", false);
    formik.setFieldValue("speaker", "");
  };

  const assignSpeakerToConf = async () => {
    await formik.setFieldTouched("speaker", true);
    await formik.validateField("speaker");
    if (Boolean(formik.errors?.speaker)) {
      return;
    }
    if (!completedStep1) {
      formik.setFieldError(
        "speaker",
        "Complete step 1 of create conference before adding speakers"
      );
      return;
    }
    const SpeakerToAdd = speakerList?.find(
      (s) => s.value === formik.values.speaker
    );
    setFormikSpeakers(SpeakerToAdd);
    await formik.setFieldValue("openSpeakerModal", false);
    formik.setFieldTouched("speaker", false);
    formik.setFieldValue("speaker", "");
  };

  const dismissSpeakerModal = () => {
    formik.setFieldValue("openSpeakerModal", false);
    formik.setFieldTouched("speaker", false);
  };

  useEffect(() => {
    if (!amenityList?.length > 0) {
      loadAmenities();
    }
  }, []);

  useEffect(() => {
    if (!premadeBanners?.length > 0) {
      loadPremadeBanners();
    }
  }, []);

  useEffect(() => {
    let confHostId;
    if (newConference?.host === "organization") {
      confHostId = newConference?.hostedBy.organization?._id;
    } else {
      confHostId = user?._id;
    }
    loadSpeakers(newConference?.host, confHostId);
  }, [formik.values.openSpeakerModal]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      formik.values.banner?.forEach((file) =>
        URL.revokeObjectURL(file.Location)
      );
  }, [formik.values.banner]);

  return (
    <main className="conf-form-wrap">
      <form
        className="form-type-1"
        onSubmit={formik.handleSubmit}
        autoComplete="off"
      >
        <p className="body-regular-gray3 mb-24" style={{ color: "#aabdc7" }}>
          All mandatory fields are makred by *
        </p>
        <div className="mb-72">
          <h2>Banner image</h2>
          <div className="mb-24">
            <h4>Add Banner*</h4>

            <p className="caption-1-regular-gray3 mb-8">
              Choose your own banner or choose one of the premade banners from
              below.
            </p>
            <p className="caption-1-regular-gray3">
              Banner must be in 16/9 ratio, at least 1080p ie 1920 x 1080
              pixels.
            </p>
          </div>
          {formik.touched.banner && Boolean(formik.errors.banner) && (
            <div className="mb-16">
              <TextError>{formik.errors.banner}</TextError>
            </div>
          )}
          <div>
            <div className="mb-8">
              {formik.values?.banner?.length > 0 ? (
                formik.values.banner?.map((image) => (
                  <div key={image.Location} className="confbanner-container">
                    <div className="confbanner-wrap">
                      <img
                        className="confbanner"
                        alt="conference banner"
                        src={image.Location}
                        // Revoke data uri after image is loaded
                        onLoad={() => {
                          URL.revokeObjectURL(image.Location);
                        }}
                      />
                    </div>
                    <div className="confbanner-overlay"></div>
                    <div
                      onClick={() => deleteBanner(image)}
                      className="confbanner-delete-circle"
                    >
                      <DeleteIcon className="icon-size" />
                    </div>
                  </div>
                ))
              ) : (
                // className="confbanner-dropzone-container " controls the size if SingleImageUploader
                <div className="confbanner-dropzone-container ">
                  <SingleImageUploader
                    fieldName="banner"
                    setFormikFieldValue={setFormikFieldValue}
                    dropzoneContentType="confbanner"
                  />
                </div>
              )}
            </div>
            <div>
              <Swiper
                cssMode={true}
                navigation={true}
                mousewheel={true}
                keyboard={true}
                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                slidesPerView={3}
                spaceBetween={10}
                // direction={"vertical"}
                pagination={{
                  clickable: true,
                }}
                className="banner-swiper"
              >
                {premadeBanners?.map((banner) => (
                  <SwiperSlide key={banner._id}>
                    <img
                      src={banner.Location}
                      alt="banner"
                      onClick={() => formik.setFieldValue("banner", [banner])}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>

        <div className="mb-72">
          <h2>Description</h2>
          <div className="mb-24">
            <p className="caption-1-regular-gray3">
              Describe conference in few words*.
            </p>
          </div>
          <div>
            <textarea
              id="description"
              name="description"
              // cols={5}
              // rows={20}
              // placeholder="Description"
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-24">
            {formik.touched.description &&
              Boolean(formik.errors.description) && (
                <TextError>{formik.errors.description}</TextError>
              )}
          </div>
        </div>

        <div className="mb-72">
          <h2>Speakers</h2>
          <div className="mb-24">
            <h4>Add one or more speakers *</h4>
          </div>
          <div className="conf-speaker-grid">
            {formik.values.speakers?.map((speaker) => (
              <Speakercard
                key={speaker?.label}
                name={speaker?.label}
                degree={speaker?.degree}
                value={speaker.value}
                designation={speaker?.designation}
                image={speaker.images}
                removeConfSpeaker={removeConfSpeaker}
              />
            ))}
            <div>
              <div
                onClick={() => formik.setFieldValue("openSpeakerModal", true)}
                className="add-speaker-button"
              >
                <div>
                  <AddGreenIcon className="icon-lg" />
                </div>
              </div>
              <div>
                {formik.touched.speakers && Boolean(formik.errors.speakers) && (
                  <TextError>{formik.errors.speakers}</TextError>
                )}
              </div>
            </div>
          </div>

          {formik.values.openSpeakerModal && (
            <ModalX
              onDismiss={dismissSpeakerModal}
              open={formik.values.openSpeakerModal}
            >
              <div className="modalx-container-md pl-16 pr-16 pb-32">
                <h4 className="flex-vchc mb-40"> Add speaker</h4>
                <div className="search-speaker-grid mt-40">
                  <div>
                    <div>
                      <SearchSpeaker
                        options={speakerList}
                        name="speaker"
                        value={formik.values.speaker}
                        onChange={(value) =>
                          formik.setFieldValue("speaker", value?.value)
                        }
                        placeholder="Choose speaker"
                        isMulit={false}
                        isDisabled={false}
                        handleOpenSpeakerForm={handleOpenSpeakerForm}
                      />
                    </div>
                    {formik.touched.speaker &&
                      Boolean(formik.errors.speaker) && (
                        <TextError>{formik.errors.speaker}</TextError>
                      )}
                  </div>
                  <div>
                    <span
                      type="button"
                      className="conf-add-newcredit-btn caption-1-heavy-primary m-4"
                      onClick={handleOpenSpeakerForm}
                    >
                      Add new speaker
                    </span>
                    {formik.touched.speaker &&
                      Boolean(formik.errors.speaker) && (
                        <div className="mb-24"></div>
                      )}
                  </div>
                  <div className="grid-col-2">
                    <button
                      type="button"
                      className="button button-primary"
                      onClick={assignSpeakerToConf}
                    >
                      Add
                    </button>
                    <button
                      type="button"
                      className="button button-green"
                      onClick={dismissSpeakerModal}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </ModalX>
          )}
        </div>

        <div className="mb-72">
          <h2>Schedules</h2>
          <div className="mb-24">
            <p className="caption-1-regular-gray3">
              Describe conference schedule.
            </p>
          </div>

          <div>
            <textarea
              id="schedules"
              name="schedules"
              // cols={5}
              // rows={20}
              // placeholder="Description"
              value={formik.values.schedules}
              onChange={formik.handleChange}
            />
          </div>
        </div>

        <div className="mb-72">
          <h2>Course outline</h2>
          <div className="mb-24">
            <p className="caption-1-regular-gray3">
              Describe conference in few words.
            </p>
          </div>

          <div>
            <textarea
              id="courseOutline"
              name="courseOutline"
              // cols={5}
              // rows={20}
              // placeholder="Description"
              value={formik.values.courseOutline}
              onChange={formik.handleChange}
            />
          </div>
        </div>

        {newConference?.venueName && newConference?.venueName?.length > 0 && (
          <div className="details2-venue-wrap">
            <h2>Venue and Amenities</h2>

            <div className=" details2-venue  ">
              <h4>{newConference?.venueName}</h4>
              <div
                style={{ fontSize: 16, color: "black" }}
                className="body-regular-gray3"
              >
                <p>{newConference?.street1}</p>
                <p>{newConference?.street2}</p>
                <p>
                  <span> {newConference?.city},</span>{" "}
                  <span> {newConference?.state}</span>
                </p>
                <p>
                  {newConference?.country}, {newConference?.zipcode}
                </p>
              </div>
            </div>

            <div className="mb-48">
              <h4>Add Venue images</h4>
              {formik.values?.venueImages?.length > 0 &&
                formik.values.venueImages?.map((image) => (
                  <div
                    key={image.Location}
                    className="confbanner-container mb-16"
                  >
                    <div className="confbanner-wrap">
                      <img
                        className="confbanner"
                        alt="venue images"
                        src={image.Location}
                        // Revoke data uri after image is loaded
                        onLoad={() => {
                          URL.revokeObjectURL(image.Location);
                        }}
                      />
                    </div>
                    <div className="confbanner-overlay"></div>
                    <div
                      className="confbanner-delete-circle"
                      onClick={() => deletedVenueImage(image)}
                    >
                      <DeleteIcon className="icon-size" />
                    </div>
                  </div>
                ))}

              <div className="confbanner-dropzone-container ">
                <MultiImageUploader
                  // fieldName="venueImages"
                  setFormikImagefieldValue={setFormikVenueImages}
                  dropzoneContentType="confbanner"
                  maxFiles={10}
                />
              </div>
            </div>
            <div className="mb-80">
              <h4>Add Venue Amenities</h4>
              <SelectFormType1
                options={amenityList}
                label="amenities"
                value={formik.values.amenities}
                onChange={(value) => {
                  return formik.setFieldValue("amenities", value);
                }}
                placeholder="Choose amenities"
                isMulti={true}
                optionLabel={(option) => {
                  return (
                    <div className="flex-vc">
                      <div className="icon-size mr-8">
                        <img
                          style={{
                            display: "block",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          src={option.icon}
                          alt="icon"
                        />
                      </div>
                      <span>{option.label}</span>
                    </div>
                  );
                }}
              />
            </div>
          </div>
        )}

        <div className="mb-72">
          <SubmitCancelButtonWithLoader
            isSubmitting={formik.isSubmitting}
            onCancel={() => {
              formik.resetForm({});
            }}
          />
        </div>
      </form>
      {showSpeakerForm && (
        <Modal onDismiss={() => setShowSpeakerForm(false)}>
          <div className="modalx-container-sm pl-16 pr-16 pb-32">
            <div className="modalx-header">
              <i
                style={{ cursor: "pointer" }}
                onClick={() => setShowSpeakerForm(false)}
              >
                <CloseIcon className="icon-size" fill="#c4c4c4" />
              </i>
            </div>
            <SpeakerForm
              setShowSpeakerForm={setShowSpeakerForm}
              updateSpeakerList={setFormikSpeakers}
              completedStep1={newConference?.completedStep1}
              host={newConference?.host}
              orgId={newConference?.hostedBy?.organization?._id}
              userId={newConference?.hostedBy?.user?._id}
            />
          </div>
        </Modal>
      )}
    </main>
  );
}
