// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import { Keyboard, Mousewheel, Navigation, Pagination } from 'swiper/modules';
// Import Swiper styles
import PropTypes from 'prop-types';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import ConfCard from '../cards/ConfCard';
import CourseCard from '../cards/CourseCard';

import './confCarousal.styles.scss';

export default function ConfCarousal({
  data,
  hideHovCard,
  // setConferenceId,
  // potentialUserForm,
}) {
  return (
    <Swiper
      slidesPerView={4}
      // spaceBetween={40}
      spaceBetween={20}
      cssMode={true}
      navigation={true}
      pagination={{
        clickable: true,
      }}
      mousewheel={true}
      keyboard={true}
      modules={[Navigation, Pagination, Mousewheel, Keyboard]}
      className="confSwiper"
      breakpoints={{
        1: {
          slidesPerView: 1,
        },
        650: {
          slidesPerView: 2,
        },
        980: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },
      }}
    >
      {data?.length > 0 &&
        data?.map((item) => (
          <SwiperSlide key={item?._id}>
            {item?.type === 'Conference' ? (
              <ConfCard
                conf={item?.conference}
                hideHovCard={true}
                tag={item?.type}
                // url={item?.conference?.url}
                // setConferenceId={setConferenceId}
                // potentialUserForm={potentialUserForm}
              />
            ) : (
              <CourseCard
                data={item?.media}
                hideHovCard={true}
                tag={item?.type}
                // url={item?.conference?.url}
                // setConferenceId={setConferenceId}
                // potentialUserForm={potentialUserForm}
              />
            )}
          </SwiperSlide>
        ))}
    </Swiper>
  );
}

ConfCarousal.propTypes = {
  data: PropTypes.array,
};
