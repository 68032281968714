import { useMemo, Fragment } from 'react';
import Select from 'react-select';
import { useDropzone } from 'react-dropzone';
import { AnimatePresence, motion } from 'framer-motion';

import AddFileIcon from '../icons/AddFileIcon';
import CloseIcon from '../icons/CloseIcon';
import {
  acceptStyle,
  baseStyle,
  focusedStyle,
  rejectStyle,
} from './fileUplaoderStyles';

import LocalAlert from '../alert/LocalAlert';
import TextError from '../formik/TextError';
import FileTitleInput from './FileTitleInput';

import { getValue } from '../../utility/commonUtil';
import { customSelectStyle3 } from '../reselect/selectUtil';

import './fileUploader.styles.scss';
import './addDocuments.scss';

export default function AddDocuments({ isProgress, formik, bookingDetails }) {
  const myDropZone = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
    },
    maxFiles: 1,
    onDrop: (acceptedFile) => {
      acceptedFile[0].title = '';
      formik.setFieldValue('file', acceptedFile);
    },
  });

  const { isFocused, isDragAccept, isDragReject, getRootProps, getInputProps } =
    myDropZone;

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  return (
    <div className="add-documents-wrap">
      <h3 className="color-primary mb-24">Upload Certificate</h3>
      <LocalAlert />
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <>
          <div className="mb-24">
            <input
              type="radio"
              style={{ display: 'none' }}
              id="conf-cert"
              name="type"
              value="creditCertificate"
              checked={formik.values.type === 'creditCertificate'}
              onChange={formik.handleChange}
            />
            <label htmlFor="conf-cert">
              <div
                style={{ fontSize: 14 }}
                className={`px-6 pt-12 pb-12 mr-8 ${
                  formik.values.type === 'creditCertificate'
                    ? 'button-outlined-active'
                    : 'button-outlined-inactive'
                }`}
              >
                Credit Certificate
              </div>
            </label>
            <input
              type="radio"
              style={{ display: 'none' }}
              name="type"
              id="cert-other"
              value="otherCertificate"
              checked={formik.values.type === 'otherCertificate'}
              onChange={formik.handleChange}
            />
            <label htmlFor="cert-other">
              <div
                style={{ fontSize: 14 }}
                className={`px-6 pt-12 pb-12 ${
                  formik.values.type === 'otherCertificate'
                    ? 'button-outlined-active'
                    : 'button-outlined-inactive'
                }`}
              >
                Other Certificate
              </div>
            </label>
          </div>
          <AnimatePresence initial={false} mode="wait">
            {formik.values.type === 'creditCertificate' && (
              <motion.div
                key="credits"
                className="mb-28"
                layout
                initial={{ scale: 0.5, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.5, opacity: 0 }}
                transition={{ type: 'spring', duration: 0.5 }}
              >
                <div>
                  <p
                    style={{ fontSize: 16 }}
                    className="body-regular-a-gray3 color-primary mb-4"
                  >
                    Credits request by user
                  </p>
                  <div className="grid-table-wrap mb-16">
                    <ul className="ucr-gridtable">
                      <li>Credit</li>
                      <li>Amount</li>
                      <li>Date</li>
                      {bookingDetails?.requestedCredits?.map((credit, indx) => {
                        return (
                          <Fragment key={indx}>
                            <li>{credit.credit.name}</li>
                            <li>{credit.quantity}</li>
                            <li>
                              {new Date(credit?.requestedDate).toDateString()}
                            </li>
                          </Fragment>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="mb-16">
                  <p
                    style={{ fontSize: 16 }}
                    className="body-regular-a-gray3 color-primary mb-4"
                  >
                    Credits already approved
                  </p>
                  <div className="grid-table-wrap">
                    <ul className="ucr-gridtable">
                      <li>Credit</li>
                      <li>Amount</li>
                      <li>Date</li>
                      {bookingDetails?.approvedCredits?.map((credit, indx) => {
                        return (
                          <Fragment key={indx}>
                            <li>{credit?.credit.name}</li>
                            <li>{credit?.quantity}</li>
                            <li>
                              {new Date(credit?.approvedDate).toDateString()}
                            </li>
                          </Fragment>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div>
                  <p
                    style={{ fontSize: 16 }}
                    className="body-regular-a-gray3 color-primary mb-4"
                  >
                    Approve Credits
                  </p>
                  <div className="gc-grid">
                    <div>
                      <Select
                        styles={customSelectStyle3}
                        value={getValue(
                          formik?.values?.creditOptions,
                          formik.values.creditId,
                          false
                        )}
                        options={formik?.values?.creditOptions || []}
                        name="creditId"
                        onChange={(value) => {
                          formik.setFieldValue('creditId', value?.value);
                          formik.setFieldValue(
                            'creditQuantity',
                            value?.quantity
                          );
                          formik.setFieldValue(
                            'creditCustomQuantity',
                            value?.quantity
                          );
                        }}
                        isDisabled={formik.values.type !== 'creditCertificate'}
                      />
                      <div>
                        {formik.touched.creditId &&
                          Boolean(formik.errors.creditId) && (
                            <TextError>{formik.errors.creditId}</TextError>
                          )}
                      </div>
                    </div>
                    <div className="form-type-3">
                      <div>
                        <input
                          id="quantity"
                          type="number"
                          name="creditCustomQuantity"
                          value={formik.values.creditCustomQuantity}
                          onChange={formik.handleChange}
                          placeholder="Credit Quantity*"
                        />
                      </div>
                      <div>
                        {formik.touched.creditCustomQuantity &&
                          Boolean(formik.errors.creditCustomQuantity) && (
                            <TextError>
                              {formik.errors.creditCustomQuantity}
                            </TextError>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </>
        <div className="filesdz-section-wrap mb-24">
          <div className="filesdz-section-innerwrap">
            <div className="filesdz-files-container">
              {formik?.values?.file?.map((file, indx) => (
                <div className="filesdz-files-row" key={file.name}>
                  <div>
                    <div className="flex-vc mb-8">
                      <AddFileIcon className="icon-xs mr-16" />
                      {file.name}
                    </div>
                    <FileTitleInput
                      value={formik?.values?.fileName}
                      onChange={(e) => {
                        formik.setFieldValue('fileName', e.target.value);
                      }}
                    />
                    <div className="mb-10">
                      {formik.touched.fileName &&
                        Boolean(formik.errors.fileName) && (
                          <TextError>{formik.errors.fileName}</TextError>
                        )}
                    </div>
                  </div>

                  <i
                    onClick={() => {
                      formik.setFieldValue('file', []);
                    }}
                  >
                    <CloseIcon className="icon-size" fill="#08415c" />
                  </i>
                </div>
              ))}
            </div>
            <div
              className={
                formik?.values?.file?.length > 0 ? 'filesdz-wrap' : null
              }
            >
              {!formik?.values?.file?.length > 0 && (
                <div
                  {
                    ...getRootProps({ style })
                    // {...getRootProps({ className: "files-dz" })
                  }
                >
                  <input {...getInputProps()} />
                  <div
                    // style={{
                    //   border:
                    //     formik.touched.file && formik.errors.file
                    //       ? '1px dashed red'
                    //       : '1px dashed grey',
                    // }}
                    className="filesdz-label-container"
                  >
                    <AddFileIcon className="icon-lg mb-16" />
                    <div
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <span>
                        Drag and drop your PDF file here or Browse to choose a
                        file
                      </span>
                      <span>Browse</span>
                      <span> to choose a file</span>
                    </div>
                    <div>
                      {formik.touched.file && formik.errors.file ? (
                        <TextError
                          sx={{
                            color: '#bf3333',
                            marginLeft: '16px !important',
                          }}
                        >
                          {formik.touched.file && formik.errors.file}
                        </TextError>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="ml-8 mr-8 mt-16 mb-16">
              <button type="submit" className="button button-primary p-4">
                {/* Upload File */}
                <span id="button-text">
                  {isProgress ? (
                    <div className="checkout-spinner" id="spinner"></div>
                  ) : (
                    'Upload'
                  )}
                </span>
              </button>
              {!isProgress && (
                <button
                  onClick={() => formik.setFieldValue('file', [])}
                  //  cleanup paths after delete
                  type="button"
                  className={'button button-green ml-8 p-4'}
                  disabled={formik.values.file.length < 1}
                >
                  Cancel
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
