import { useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SectionForm from "./SectionForm";
import { v4 as uuid } from "uuid";
import { createVideoCourseAction } from "../../redux/video-course/videoCourseAction";
import api from "../../utility/api";
import CreateChapter from "./CreateChapter";
import EpisodeList from "./EpisodeList";
import PlusIcon from "../icons/PlusIcon";
import AddChapterButton from "./AddChapterButton";

export default function VideoCourseStep3() {
  const video = useSelector((state) => state.video);
  const sections = video?.newVideoCourse?.sections;
  const [mySections, setMySections] = useState(sections || []);
  const [showChapter, setShowChapter] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [chapterToEdit, setChapterToEdit] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteSection = async (courseId, sectionId) => {
    const url = `courses/${courseId}/videoCourse/section/${sectionId}`;
    try {
      const response = await api.delete(url);

      dispatch(createVideoCourseAction(response.data.data.media));
      setMySections(response.data.data?.media?.sections);
    } catch (err) {}
  };

  const handleNext = () => {
    navigate("/dashboard/create-vc/step-4");
  };

  return (
    <div className="create-event-wrap">
      {showChapter ? (
        <CreateChapter
          setShowChapter={setShowChapter}
          selectedSection={selectedSection}
          setMySections={setMySections}
          episodeToEdit={chapterToEdit}
          setEpisodeToEdit={setChapterToEdit}
        />
      ) : (
        <>
          <p className="body-regular-gray3 mb-24" style={{ color: "#aabdc7" }}>
            All mandatory fields are makred by *
          </p>
          <h2 className="mb-32 color-primary">Course Structure</h2>
          <div>
            {mySections?.map((section, index) => (
              <div className="mb-44" key={section?.uuid}>
                <div className="mb-16">
                  <SectionForm
                    section={section}
                    sectionIndex={index}
                    setMySections={setMySections}
                  />
                </div>
                {section?.episodes?.length > 0 && (
                  <div className="mb-16">
                    <EpisodeList
                      list={section?.episodes}
                      setMySections={setMySections}
                      setChapterToEdit={setChapterToEdit}
                      setShowChapter={setShowChapter}
                    />
                  </div>
                )}
                <AddChapterButton
                  section={section}
                  setSelectedSection={setSelectedSection}
                  setShowChapter={setShowChapter}
                />
              </div>
            ))}
          </div>
          <div className="ce-section-grid mb-16">
            <div
              className="add-section-button"
              onClick={() =>
                setMySections((prev) => [
                  ...prev,
                  {
                    title: "",
                    uuid: uuid(),
                  },
                ])
              }
            >
              <i className="position-relative pt-5 mr-8">
                <PlusIcon className="icon-size" fill="#38b52f" />
              </i>
              <span>Add Section</span>
            </div>
            <div className="create-event-res">{/* leave this empty */}</div>
          </div>
          <div
            style={{
              marginBottom: 120,
            }}
          >
            <AddChapterButton />
          </div>
          <div className="submit-cancel-loader-wrap">
            <button
              onClick={() => handleNext()}
              className="button button-green "
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
}
