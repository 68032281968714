import { useSelector } from 'react-redux';
import { getPrice } from '../../utility/commonUtil';
import './podcastyearlyprice.scss';

export default function PodcastMonthlyPrice() {
  const podcast = useSelector((state) => state.podcast.podcast);

  return (
    <div className="cep-card-bottombox">
      <div className="flex-vc-sb  mb-8">
        <p className="avenir-roman-18 podcast-price-text">Price</p>
        {/* <p
          className="avenir-heavy-18 color-primary"
          style={{
            ...(podcast?.monthlyPodcastPrice?.discount && {
              textDecoration: 'line-through',
              color: '#4cb944',
            }),
          }}
        > */}
        {/* <span>{podcast?.currency} </span> */}
        <p
          className="flex-vc caption-2-bold-cblack podcast-price-text"
          style={{
            fontSize: 16,
            gap: '8px',
            wordBreak: 'break-word',
          }}
        >
          {getPrice(
            podcast?.currency,
            podcast?.monthlyPodcastPrice?.actualPrice,
            podcast?.monthlyPodcastPrice?.price,
            podcast?.currencySymbol
          )}
        </p>
      </div>
      {podcast?.monthlyPodcastPrice?.discount && (
        <div className="flex-vc-sb  mb-8">
          <p className="avenir-roman-18 podcast-price-text">Offer</p>
          <p className="avenir-heavy-18 podcast-price-text">
            {' '}
            <span>{podcast?.monthlyPodcastPrice?.discount}</span> % Off
          </p>
        </div>
      )}
      <div className="flex-vc-sb  mb-8">
        <p className="avenir-roman-18 podcast-price-text">Service charge</p>
        <p className="avenir-heavy-18 podcast-price-text">
          {' '}
          {podcast.currency}{' '}
          {podcast?.monthlyPodcastPrice?.actualPrice * (5 / 100)}
        </p>
      </div>
      {podcast?.monthlyPodcastPrice?.discount && (
        <div className="flex-vc-sb  mb-8">
          <p className="avenir-roman-18 podcast-price-text">Total</p>
          <p className="avenir-heavy-18 podcast-price-text">
            <span>{podcast?.currency} </span>
            <span>
              {podcast?.monthlyPodcastPrice?.actualPrice +
                podcast?.monthlyPodcastPrice?.actualPrice * (5 / 100)}
            </span>
          </p>
        </div>
      )}
    </div>
  );
}
