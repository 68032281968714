import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { alertAction } from "../../redux/alert/alertAction";
import HoverCard from "./HoverCard";

import {
  getLocationString,
  openInNewTab,
  likeConference,
  unlikeConference,
  getFormattedDateInTz2,
  getPrice,
} from "../../utility/commonUtil";
import CameraIcon from "../icons/CameraIcon";
import CreditsIcon from "../icons/CreditsIcon";
import DateIcon from "../icons/DateIcon";
import LikeBlueIcon from "../icons/LikeBlueIcon";
import LikeInactiveIcon from "../icons/LikeInactiveIcon";
import LocationIcon from "../icons/LocationIcon";
import { useNavigate } from "react-router-dom";

export default function ConfCard({ conf, hideHovCard }) {
  const [isLiked, setIsLiked] = useState(false);

  // const user = useSelector((state) => state.auth.user);
  const auth = useSelector((state) => state.auth);
  const { isAuthenticated, user } = auth;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const like = (confId, userId, setIsLiked) => {
    if (!isLiked && isAuthenticated && user) {
      likeConference(confId, userId, setIsLiked);
    } else {
      dispatch(alertAction("Please login to like a conference.", "info"));
    }
  };

  const unlike = (confId, userId, setIsLiked) => {
    if (isLiked && isAuthenticated && user) {
      unlikeConference(confId, userId, setIsLiked);
    } else {
      dispatch(alertAction("Please login to unlike a conference.", "info"));
    }
  };
  console.log({ conf });
  useEffect(() => {
    setIsLiked(conf?.isLiked);
  }, [conf?.isLiked]);

  return (
    <div className="conf-card ">
      <div
        className="cc-img-container"
        onClick={
          () => {
            openInNewTab(`/search-conference/${conf?._id}`);
          }
          //   navigate(`/search-conference/${confId}`);
        }
      >
        {conf?.banner?.[0]?.Location ? (
          <img src={conf?.banner[0]?.Location} alt="conference banner" />
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              padding: 40,
              backgroundColor: "#ecf0f2",
            }}
          >
            <i>
              <CameraIcon className="icon-size" />
            </i>
          </div>
        )}
      </div>
      <div className="cc-content-container">
        <div
          className="cc-headtrunc-wrap"
          onClick={
            () => {
              openInNewTab(`/search-conference/${conf?._id}`);
            }
            // () => navigate(`/search-conference/${confId}`)
          }
        >
          <div className="coursecard-header">{conf?.title}</div>
          <div className="confcard-trunc mt-8">
            <div className="flex-vc  mb-8">
              <DateIcon className="icon-xxs mr-8" />
              <span className="caption-2-regular-gray3 mr-4">Start: </span>
              <span className="caption-2-regular-gray3  cc-truncitem-wrap">
                {getFormattedDateInTz2(conf?.startDate, conf?.timezone)}
              </span>
            </div>
            <div className="flex-vc  mb-8">
              <DateIcon className="icon-xxs mr-8" />
              <span className="caption-2-regular-gray3 mr-4">End: </span>
              <span className="caption-2-regular-gray3  cc-truncitem-wrap">
                {getFormattedDateInTz2(conf?.endDate, conf?.timezone)}
              </span>
            </div>
            <div className="flex-vc  mb-8">
              <LocationIcon className="icon-xxs mr-8" />
              <span className="caption-2-regular-gray3 cc-truncitem-wrap">
                Location: {getLocationString(conf?.mode, conf?.city)}
              </span>
            </div>
            <div className="flex-vc  mb-8">
              <CreditsIcon className="icon-xxs mr-8" />
              <span className="caption-2-regular-gray3 cc-truncitem-wrap">
                Credits:
                {conf?.credits?.length > 0
                  ? ` ${conf?.credits[0].creditId?.name} - ${conf?.credits[0].quantity}`
                  : " Credits not added"}
              </span>
            </div>
          </div>
        </div>
        <div className="confcard-footer">
          {/* <div className="flex-vc-sb ">
            <span
              className="caption-2-bold-cblack cc-truncitem-wrap"
              style={{ fontSize: 16 }}
            >
              {conf?.currency && conf?.basePrice > 0
                ? `${conf?.currency} -  
                    ${conf?.basePrice}`
                : conf?.currency && conf?.basePrice === 0
                ? "Free"
                : "Price not availabe"}
            </span>
          </div> */}
          <div
            className="flex-vc caption-2-bold-cblack "
            style={{
              width: "100%",
              height: "100%",
              fontSize: 16,
              gap: "8px",
              wordBreak: "break-word",
            }}
          >
            {getPrice(
              conf?.currency,
              conf?.actualPrice,
              conf?.basePrice,
              conf?.currencySymbol
            )}
          </div>
          <div style={{ cursor: "pointer" }}>
            {isLiked ? (
              <i
                className="conference-card-buttons"
                onClick={() => unlike(conf?._id, user?._id, setIsLiked)}
              >
                {/* <LikeRedIcon className="icon-sm" /> */}
                <LikeBlueIcon className="icon-size" />
              </i>
            ) : (
              <i
                className="conference-card-buttons"
                onClick={() => like(conf?._id, user?._id, setIsLiked)}
              >
                <LikeInactiveIcon className="icon-size" />
              </i>
            )}
          </div>
        </div>
      </div>
      {!hideHovCard && <HoverCard conf={conf} />}
    </div>
  );
}

ConfCard.propTypes = {
  conf: PropTypes.object.isRequired,
  hideHovCard: PropTypes.bool,
};
