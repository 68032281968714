import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Select from "react-select";
import AsyncSelect from "react-select/async";

import DropdownIcon from "../icons/DropdownIcon";
import NextIcon from "../icons/NextIcon";

import { alertAction } from "../../redux/alert/alertAction";
import api from "../../utility/api";
import {
  currencylist,
  professions,
  subspecialties,
  initialDisplay,
} from "../../utility/commonUtil";
import OnlyDatepicker from "../react-datepicker/OnlyDatePicker";
import "./searchFilters.styles.scss";

const confemyWhite = "#ffffff";
const confemyBlac = "#000000";
const shade1 = "#ced9de";
const shade2 = "#ecf0f2";
const shade3 = "#fcfdfd";
const shade4 = "#aabdc7";

const customStyles = {
  control: (styles, state) => {
    return {
      ...styles,
      backgroundColor: state.isDisabled ? shade2 : confemyWhite,
      border: state.isFocused ? "1px solid #55a0fa" : `1px solid ${shade1}`,
      padding: "4px 0px 4px 0px",
      fontFamily: "Avenir-Roman",
      fontSize: "1.6rem",
      ":hover": {
        border: state.isFocused ? "1px solid #55a0fa" : `solid 1px ${shade4}`,
      },
    };
  },
  placeholder: (provided, state) => {
    return {
      ...provided,
      color: state.isDisabled ? shade4 : "hsl(0, 0%, 50%)",
      fontFamily: "Avenir-Roman",
      fontSize: "1.6rem",
    };
  },
  option: (provided, state) => {
    return {
      ...provided,
      color: confemyBlac,
      fontSize: "1.4rem",
      fontFamily: "Avenir-Roman",
    };
  },
  dropdownIndicator: (provided, state) => {
    return {
      ...provided,
      paddingRight: 6,
      paddingLeft: 4,
    };
  },

  noOptionsMessage: (provided, state) => {
    return {
      ...provided,
      backgroundColor: shade3,
      color: confemyBlac,
      fontSize: 16,
    };
  },
};

export default function SearchFilters({
  filters,
  loadLocations,
  onLocationChange,
  onFilterDateChange,
  onProfessionChange,
  onSpecialitiesChange,
  onCreditTypeChange,
  onCreditAmountChange,
  onCurrencyChange,
  onFilterPriceChange,
  clearAllFilters,
}) {
  const { specialityDisabled, creditAmountDisabled, priceDisabled } = filters;

  const [display, setDisplay] = useState(initialDisplay);
  const {
    openLocation,
    openDate,
    openProfession,
    openSpecialty,
    openCredits,
    openPrice,
  } = display;

  const [creditOptions, setCreditOptions] = useState([]);

  const dispatch = useDispatch();

  const getValue = (options, value, isMulti) => {
    if (isMulti) {
      return value;
    } else {
      return options ? options?.find((option) => option.value === value) : "";
    }
  };

  // load credit types from backend
  async function getCreditTypes() {
    try {
      const response = await api.get("common/conferences/credits");
      if (response) {
        setCreditOptions(response.data.data.credits);
      }
    } catch (err) {
      if (err) dispatch(alertAction(err.response?.data?.message, "danger"));
    }
  }

  useEffect(() => {
    getCreditTypes();
  }, []);

  useEffect(() => {
    const savedDisplay = JSON.parse(window.localStorage.getItem("CS_DISPLAY"));
    if (savedDisplay !== null) setDisplay(savedDisplay);
  }, []);

  useEffect(() => {
    window.localStorage.setItem("CS_DISPLAY", JSON.stringify(display));
    return () => {
      window.localStorage.removeItem("CS_DISPLAY");
    };
  }, [display]);

  return (
    <div>
      <div className="sf-header">
        <h3>Filters</h3>
        <div
          onClick={() => clearAllFilters()}
          className="caption-1-heavy-gray3 clearall-btn-wrap"
        >
          Clear All
        </div>
      </div>
      <div
        // onClick={() => setOpenLocation((prev) => !prev)}
        onClick={() =>
          setDisplay((prev) => ({ ...prev, openLocation: !prev.openLocation }))
        }
        className={openLocation ? "active-sf-item" : "sf-item"}
      >
        <p>Location</p>
        {openLocation ? (
          <DropdownIcon className="icon-size" />
        ) : (
          <NextIcon className="icon-size" />
        )}
      </div>
      <div className={openLocation ? "sf-input-wrap" : "display-none"}>
        <AsyncSelect
          value={filters.location}
          onChange={onLocationChange}
          placeholder={"Select country, state or city..."}
          loadOptions={loadLocations}
          isClearable
          styles={customStyles}
        />
      </div>
      <div
        onClick={() =>
          setDisplay((prev) => ({ ...prev, openDate: !prev.openDate }))
        }
        className={openDate ? "active-sf-item" : "sf-item"}
      >
        <p>Date</p>
        {openDate ? (
          <DropdownIcon className="icon-size" />
        ) : (
          <NextIcon className="icon-size" />
        )}
      </div>
      <div className={openDate ? "sf-input-wrap flex" : "display-none"}>
        <div className="mr-4">
          <OnlyDatepicker
            name="startDate"
            selected={filters.startDate}
            onChange={(date) => {
              onFilterDateChange(date, "startDate");
            }}
            // onChange={(date) => {

            //   return setStartDate(date);
            // }}
            // minDate={new Date()}
            placeholder="Start date"
            disabled={false}
          />
        </div>
        <div className="ml-4">
          <OnlyDatepicker
            name="endDate"
            selected={filters.endDate}
            onChange={(date) => {
              onFilterDateChange(date, "endDate");
            }}
            // onChange={(date) => setEndDate(date)}
            minDate={filters.endDate}
            placeholder="End date"
            disabled={false}
          />
        </div>
      </div>
      <div
        onClick={() =>
          setDisplay((prev) => ({
            ...prev,
            openProfession: !prev.openProfession,
          }))
        }
        className={openProfession ? "active-sf-item" : "sf-item"}
      >
        <p>Profession</p>
        {openProfession ? (
          <DropdownIcon className="icon-size" />
        ) : (
          <NextIcon className="icon-size" />
        )}
      </div>
      <div className={openProfession ? "sf-input-wrap" : "display-none"}>
        <Select
          key={getValue(professions, filters.profession)}
          name="profession"
          options={professions}
          value={getValue(professions, filters.profession)}
          onChange={onProfessionChange}
          placeholder="Select Profession"
          isClearable={true}
          isMulti={false}
          styles={customStyles}
        />
      </div>
      <div
        onClick={() =>
          setDisplay((prev) => ({
            ...prev,
            openSpecialty: !prev.openSpecialty,
          }))
        }
        className={openSpecialty ? "active-sf-item" : "sf-item"}
      >
        <p>Specialty</p>
        {openSpecialty ? (
          <DropdownIcon className="icon-size" />
        ) : (
          <NextIcon className="icon-size" />
        )}
      </div>
      <div className={openSpecialty ? "sf-input-wrap" : "display-none"}>
        <Select
          name="specialties"
          value={filters.specialities}
          isMulti
          options={subspecialties}
          placeholder="Select specialties"
          onChange={onSpecialitiesChange}
          isClearable={false}
          styles={customStyles}
          isDisabled={specialityDisabled}
        />
      </div>

      <div
        onClick={() =>
          setDisplay((prev) => ({ ...prev, openCredits: !prev.openCredits }))
        }
        className={openCredits ? "active-sf-item" : "sf-item"}
      >
        <p>Credits</p>
        {openCredits ? (
          <DropdownIcon className="icon-size" />
        ) : (
          <NextIcon className="icon-size" />
        )}
      </div>
      <div className={openCredits ? "sf-input-wrap" : "display-none"}>
        <Select
          key={getValue(creditOptions, filters.creditType)}
          name="creditType"
          options={creditOptions}
          value={getValue(creditOptions, filters.creditType)}
          onChange={onCreditTypeChange}
          placeholder="Select credit"
          isClearable={true}
          isMulti={false}
          styles={customStyles}
        />
        <div className="form-type-3 mt-8">
          <input
            style={{
              ...(creditAmountDisabled && {
                backgroundColor: shade2,
                color: shade4,
              }),
            }}
            type="number"
            name="creditAmount"
            placeholder="Credit Amount"
            min="1"
            value={filters.creditAmount}
            onChange={onCreditAmountChange}
            disabled={creditAmountDisabled}
          />
        </div>
      </div>
      <div
        onClick={() =>
          setDisplay((prev) => ({ ...prev, openPrice: !prev.openPrice }))
        }
        className={openPrice ? "active-sf-item" : "sf-item"}
      >
        <p>Price</p>
        {openPrice ? (
          <DropdownIcon className="icon-size" />
        ) : (
          <NextIcon className="icon-size" />
        )}
      </div>
      <div className={openPrice ? "sf-input-wrap" : "display-none"}>
        <Select
          key={getValue(currencylist, filters.currency)}
          name="currency"
          options={currencylist}
          value={getValue(currencylist, filters.currency)}
          onChange={onCurrencyChange}
          placeholder="Select currency"
          isClearable={true}
          isMulti={false}
          styles={customStyles}
        />
        <div className="form-type-3 mt-8">
          <p className="caption-3 ml-4">Min Price</p>
          <input
            style={{
              ...(priceDisabled && {
                backgroundColor: shade2,
                color: shade4,
              }),
            }}
            type="number"
            name="minPrice"
            min="0"
            placeholder="Min Price"
            value={filters.minPrice}
            onChange={(e) => onFilterPriceChange(e, "minPrice")}
            disabled={priceDisabled}
          />
        </div>
        <div className="form-type-3 mt-8">
          <p className="caption-3 ml-4">Max Price</p>
          <input
            style={{
              ...(priceDisabled && {
                backgroundColor: shade2,
                color: shade4,
              }),
            }}
            // style={{ backgroundColor: priceDisabled ? shade2 : null }}
            type="number"
            name="maxPrice"
            placeholder="Max Price"
            value={filters.maxPrice}
            min="1"
            onChange={(e) => onFilterPriceChange(e, "maxPrice")}
            disabled={priceDisabled}
          />
        </div>
      </div>
    </div>
  );
}
