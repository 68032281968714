import { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alertAction } from '../../../redux/alert/alertAction';

import { loadUserSpeakers } from '../../../redux/user-profile/userProfileAction';
import EditIcon from '../../icons/EditIcon';
import './UserSpeakerTable.scss';

import Select from 'react-select';
import { loadMyOrganizationsSelectListAction } from '../../../redux/organization/myOrganizationsAction';
import api from '../../../utility/api';
import { getValue, scrollToTop } from '../../../utility/commonUtil';
import '../../conference/myConfs.styles.scss';
import Dialogue from '../../dialogue/Dialogue';
import AddIcon from '../../icons/AddIcon';
import DeleteIcon from '../../icons/DeleteIcon';
import ProfileSketch from '../../icons/ProfileSketch';
import SearchIcon from '../../icons/SearchIcon';
import ModalX from '../../modal/ModalX';
import { customSelectStyle3 } from '../../reselect/selectUtil';
import SpeakerForm from './UserSpeakerForm';
import { renderRowStyle } from '../../../utility/commonUtil';
import CustomPagination from '../../pagination/CustomPagination';
import PageLimitSelect from '../../pagination/PageLimitSelect';
import Loader from '../../loader/Loader';

const UserSpeakerTable = () => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [speakerToDelete, setSpeakerToDelete] = useState(null);
  const [speakerToEdit, setSpeakerToEdit] = useState(null);
  const [showSpeakerForm, setShowSpeakerForm] = useState(false);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchFilter, setSearchFilter] = useState('all');
  const [isLoading, setIsLoading] = useState(false);

  // for pagination
  const [currentPageNumber, setCurrentPageNumber] = useState({
    currentPage: 1,
  });
  const [activePage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);

  const speakersList = useSelector((state) => state.userProfile.userSpeakers);
  const organizationsListForSelect = useSelector(
    (state) => state.myOrganizations.organizationsListForSelect
  );
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  // to highlight row on hover
  const [isHover, setIsHover] = useState(false);
  const [rowId, setRowId] = useState(null);

  const handleMouseEnter = (rowId) => {
    setRowId(rowId);
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setRowId(null);
    setIsHover(false);
  };

  //  on clicking a row cell edit the speaker
  function onRowClick(speaker) {
    setSpeakerToEdit(speaker);
    setOpenEditModal(true);
  }

  const getConferenceSpeakers = async (
    userId,
    searchText,
    searchFilter,
    page,
    limit
  ) => {
    try {
      const response = await api.get(
        `/speakers?page=${page}&&limit=${limit}&&_id=${user?._id}&&asset=true&&searchText=${searchText}&&filter=${searchFilter}`
      );
      console.log({ response });
      if (response.data.data?.page === 1 && response.data.data?.isSetPage) {
        setCurrentPageNumber({ currentPage: response.data.data?.page });
        setCurrentPage(response.data.data?.page);
      }
      setTotalRecords(response.data.data.speakerCount);
      dispatch(loadUserSpeakers(response.data.data.speakers));
    } catch (err) {
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  };

  const deleteSpeaker = async (speakerId) => {
    try {
      setIsLoading(true);
      const response = await api.delete(
        `/speakers/${speakerId}?page=${activePage}&&limit=5&&_id=${user?._id}&&asset=true&&searchText=${searchText}&&filter=${searchFilter}`
      );
      if (response) {
        setOpenDialogue(false);

        if (response.data.data?.isSetPage) {
          setCurrentPageNumber({ currentPage: response.data.data?.page });
          setCurrentPage(response.data.data?.page);
        }
        setTotalRecords(response.data.data.speakerCount);
        dispatch(loadUserSpeakers(response.data.data.speakers));
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  };

  const loadPaginationData = (data) => {
    const { currentPage, pageLimit } = data;

    getConferenceSpeakers(
      user?._id,
      searchText,
      searchFilter,
      currentPage,
      pageLimit
    );
    setCurrentPage(currentPage);
  };

  const options2 = [
    { value: 'all', label: 'All' },
    { value: 'user', label: 'User' },
    ...organizationsListForSelect,
  ];
  const loadMyOrgnizations = async (id) => {
    const url = `organizations/users/${id}?orgForConference=true`;
    try {
      const response = await api.get(url);

      if (response) {
        dispatch(
          loadMyOrganizationsSelectListAction(response.data?.data?.organization)
        );
      }
    } catch (err) {
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  };

  useEffect(() => {
    getConferenceSpeakers(user?._id, '', 'all', activePage, pageLimit);
  }, [user?._id]);

  useEffect(() => {
    loadMyOrgnizations(user?._id);
  }, [user?._id]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <div className="myconfs-header mb-24">
        <h1>Speakers</h1>
        <button
          onClick={() => {
            setShowSpeakerForm(true);
          }}
          className="button button-green"
        >
          <div className="flex-vc">
            <AddIcon className="icon-size" fill="#fff" />
            <p className="ml-8">Add Speaker</p>
          </div>
        </button>
      </div>

      <div className="two-filters-wrap mb-24">
        <div style={{ position: 'relative' }} className="form-type-3">
          <input
            type="text"
            id="myConfsSearchText"
            placeholder="Search Speakers"
            name="searchText"
            value={searchText}
            onChange={(e) => {
              // setCurrentPage(1);
              // setCurrentPageNumber({ currentPage: 1 });

              getConferenceSpeakers(
                user?._id,
                e.target.value,
                searchFilter,
                activePage,
                pageLimit
              );
              setSearchText(e.target.value);
            }}
            autoComplete="off"
          />
          <i
            className={
              searchText?.length > 0 ? 'display-none' : 'conf-search-input-icon'
            }
          >
            <SearchIcon width="2.4rem" height="2.4rem" />
          </i>
        </div>

        <Select
          styles={customSelectStyle3}
          value={getValue(options2, searchFilter, false)}
          options={options2}
          placeholder="Filter"
          name="filter"
          onChange={(val) => {
            getConferenceSpeakers(
              user?._id,
              searchText,
              val.value,
              activePage,
              pageLimit
            );
            setSearchFilter(val?.value);
          }}
        />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="table-container">
            <ul className="us-gridtable grid-table-6-700 ">
              <li>Image</li>
              <li>Name</li>
              <li>Designation</li>
              <li>Degree</li>
              <li>Host</li>
              <li>Actions</li>
              {speakersList?.length > 0 &&
                speakersList?.map((speaker, indx) => {
                  return (
                    <Fragment key={speaker._id}>
                      <li
                        onMouseEnter={() => handleMouseEnter(speaker?._id)}
                        onMouseLeave={() => handleMouseLeave(speaker?._id)}
                        style={renderRowStyle(
                          indx,
                          speaker?._id,
                          isHover,
                          rowId
                        )}
                        onClick={() => onRowClick(speaker)}
                      >
                        {speaker?.images[0]?.Location ? (
                          <div className="st-image-wrap">
                            <img src={speaker?.images[0]?.Location}></img>
                          </div>
                        ) : (
                          <div
                            style={{ border: '2px solid #ced9de' }}
                            className="st-image-wrap"
                          >
                            <ProfileSketch className="icon-lg" fill="#c4c4c4" />
                          </div>
                        )}
                      </li>
                      <li
                        onMouseEnter={() => handleMouseEnter(speaker?._id)}
                        onMouseLeave={() => handleMouseLeave(speaker?._id)}
                        style={renderRowStyle(
                          indx,
                          speaker?._id,
                          isHover,
                          rowId
                        )}
                        onClick={() => onRowClick(speaker)}
                      >
                        {speaker.label}
                      </li>
                      <li
                        onMouseEnter={() => handleMouseEnter(speaker?._id)}
                        onMouseLeave={() => handleMouseLeave(speaker?._id)}
                        style={renderRowStyle(
                          indx,
                          speaker?._id,
                          isHover,
                          rowId
                        )}
                        onClick={() => onRowClick(speaker)}
                      >
                        {speaker.designation}
                      </li>
                      <li
                        onMouseEnter={() => handleMouseEnter(speaker?._id)}
                        onMouseLeave={() => handleMouseLeave(speaker?._id)}
                        style={renderRowStyle(
                          indx,
                          speaker?._id,
                          isHover,
                          rowId
                        )}
                        onClick={() => onRowClick(speaker)}
                      >
                        {speaker.degree}
                      </li>
                      <li
                        onMouseEnter={() => handleMouseEnter(speaker?._id)}
                        onMouseLeave={() => handleMouseLeave(speaker?._id)}
                        style={renderRowStyle(
                          indx,
                          speaker?._id,
                          isHover,
                          rowId
                        )}
                        onClick={() => onRowClick(speaker)}
                      >
                        {speaker?.host === 'organization' ? 'org' : 'Self'}
                      </li>
                      <li
                        onMouseEnter={() => handleMouseEnter(speaker?._id)}
                        onMouseLeave={() => handleMouseLeave(speaker?._id)}
                        style={renderRowStyle(
                          indx,
                          speaker?._id,
                          isHover,
                          rowId
                        )}
                        // onClick={() => onRowClick(speaker)}
                      >
                        <div
                          className="flex-vc"
                          style={{ justifyContent: 'flex-end' }}
                        >
                          <i
                            className="mr-8"
                            onClick={(e) => {
                              e.stopPropagation();
                              onRowClick(speaker);
                            }}
                          >
                            <EditIcon className="icon-size" fill="#08415c" />
                          </i>
                          <i
                            onClick={(e) => {
                              e.stopPropagation();
                              setSpeakerToDelete(speaker);
                              setOpenDialogue(true);
                            }}
                          >
                            <DeleteIcon />
                          </i>
                        </div>
                      </li>
                    </Fragment>
                  );
                })}
            </ul>
          </div>
          <div className="flex-vc-sb">
            <div>
              <CustomPagination
                currentPageNumber={currentPageNumber}
                setCurrentPage={setCurrentPageNumber}
                totalRecords={totalRecords}
                pageLimit={pageLimit}
                pageNeighbours={1}
                onPageChanged={(data) => loadPaginationData(data)}
              />
            </div>
            <div>
              <PageLimitSelect
                pageLimit={pageLimit}
                setPageLimit={setPageLimit}
                activePage={currentPageNumber}
                totalRecords={totalRecords}
                loadPaginationData={loadPaginationData}
              />
            </div>
          </div>{' '}
        </>
      )}

      {showSpeakerForm && (
        <ModalX
          onDismiss={() => setShowSpeakerForm(false)}
          open={showSpeakerForm}
        >
          <div className="modalx-container-md pl-16 pr-16 pb-24 pt-32">
            <SpeakerForm
              editMode={false}
              setShowSpeakerForm={setShowSpeakerForm}
              page={activePage}
              setCount={setTotalRecords}
              filter={searchFilter}
              searchText={searchText}
            />
          </div>
        </ModalX>
      )}
      {openEditModal && (
        <ModalX
          onDismiss={() => {
            setSpeakerToEdit(null);
            setOpenEditModal(false);
          }}
          open={openEditModal}
        >
          <div className="modalx-container-md pl-16 pr-16 pb-32 pt-32">
            <SpeakerForm
              editMode={true}
              editData={speakerToEdit}
              setShowSpeakerForm={setOpenEditModal}
              page={activePage}
              setCount={setTotalRecords}
              filter={searchFilter}
              searchText={searchText}
            />
          </div>
        </ModalX>
      )}

      {openDialogue && (
        <Dialogue
          msg="Are you sure you want to delete the speaker?"
          title="Confirm Delete !!"
          closeDialogue={() => {
            setOpenDialogue(false);
            setSpeakerToDelete(null);
          }}
          yesAction={() => deleteSpeaker(speakerToDelete?._id)}
        />
      )}
    </>
  );
};

export default UserSpeakerTable;
