import CameraIcon from '../icons/CameraIcon';
import { getPrice } from '../../utility/commonUtil';
import MediaTag from '../tags/MediaTag';

export default function CourseBookingPageCard({
  media,
  episodeCount,
  sectionCount,
}) {
  return (
    <div className="bpc-card">
      <div className="bpc-img-container">
        {media?.banner?.length > 0 ? (
          <img className="" alt="preview" src={media?.banner[0]?.Location} />
        ) : (
          <div className="">
            <div className="text-align-center">
              <i>
                <CameraIcon className="icon-size" />{' '}
              </i>
            </div>
          </div>
        )}
      </div>
      <div>
        <div className="mb-8">
          <MediaTag tag="Updated" bg="yellow" />
        </div>
        <div className="coursecard-header mb-8">{media?.title}</div>
        <p className="mb-4">
          <span className="avenir-16-500">
            {media?.hostedBy?.user
              ? `by ${media?.hostedBy?.user?.firstName} ${media?.hostedBy?.user?.lastName}`
              : `by ${media?.hostedBy?.organization?.name}`}
          </span>
        </p>
        <div
          className="avenir-16-500 flex-vc flex-vc-wrap mb-8"
          style={{ gap: 8, fontSize: 14 }}
        >
          <p>
            {sectionCount > 0
              ? sectionCount === 1
                ? `• ${sectionCount} section`
                : `• ${sectionCount} sections`
              : null}
          </p>{' '}
          <p>
            {episodeCount > 0
              ? episodeCount === 1
                ? `• ${episodeCount} episode`
                : `• ${episodeCount} episodes`
              : null}
          </p>{' '}
          <p>{media?.duration && `• total ${media?.duration}`} </p>{' '}
        </div>
        {media?.type !== 'PODCAST' && (
          <div
            className="flex-vc caption-2-bold-cblack"
            style={{
              fontSize: 16,
              gap: '8px',
              wordBreak: 'break-word',
            }}
          >
            {getPrice(
              media?.currency,
              media?.actualPrice,
              media?.price,
              media?.currencySymbol
            )}
          </div>
        )}
      </div>
    </div>
  );
}
