import { useSelector } from "react-redux";
import MaterialTextInput from "../formik/MaterialTextInput";
import ReloadableSelectFormType1 from "../reselect/ReloadableSelectFormType1";
import TextError from "../formik/TextError";
import {
  loadCityList,
  loadCountryList,
  loadStateList,
  getValue,
} from "../../utility/commonUtil";
import Select from "react-select";
import { modalStyleformType1 } from "../reselect/selectUtil";

export default function BankFormPart1({ formikProps }) {
  const { countryList, stateList, cityList } = useSelector(
    (state) => state.list
  );
  return (
    <>
      <div className="mb-16">
        <MaterialTextInput
          id="bank"
          type="text"
          label="Bank name*"
          name="bankName"
          placeholder=" "
        />
      </div>

      <div className="basicInfo-grid ">
        <div>
          <MaterialTextInput
            id="address1"
            type="text"
            label="Address Line 1*"
            name="address1"
            placeholder=" "
          />
        </div>
        <div>
          <MaterialTextInput
            id="address2"
            type="text"
            label="Address Line 2"
            name="address2"
            placeholder=" "
          />
        </div>
        <div>
          <Select
            label="Country"
            name="country"
            options={countryList}
            onChange={(value) => {
              formikProps.setFieldValue("country", value?.value);
              loadStateList(value?.countryId);
            }}
            placeholder="Country*"
            value={getValue(countryList, formikProps.values.country, false)}
            styles={modalStyleformType1}
            menuPortalTarget={document.body}
          />
          <div>
            {formikProps.touched.country &&
              Boolean(formikProps.errors.country) && (
                <TextError>{formikProps.errors.country}</TextError>
              )}
          </div>
        </div>
        <div>
          <Select
            label="State"
            name="state"
            options={stateList}
            value={getValue(stateList, formikProps.values.state, false)}
            onChange={(value) => {
              formikProps.setFieldValue("state", value?.value);
              loadCityList(value?.stateId);
            }}
            placeholder="State*"
            styles={modalStyleformType1}
            menuPortalTarget={document.body}
          />
          <div>
            {formikProps.touched.state && Boolean(formikProps.errors.state) && (
              <TextError>{formikProps.errors.state}</TextError>
            )}
          </div>
        </div>
        <div>
          <Select
            label="City"
            name="city"
            options={cityList}
            value={getValue(stateList, formikProps.values.city, false)}
            onChange={(value) => {
              formikProps.setFieldValue("city", value?.value);
            }}
            placeholder="City*"
            styles={modalStyleformType1}
            menuPortalTarget={document.body}
          />
          <div>
            {formikProps.touched.city && Boolean(formikProps.errors.city) && (
              <TextError>{formikProps.errors.city}</TextError>
            )}
          </div>
        </div>
        <div>
          <MaterialTextInput
            id="zipcode"
            type="text"
            label="Zipcode*"
            name="zipcode"
            placeholder=" "
          />
        </div>
      </div>
    </>
  );
}
