export default function VideoIcon({ className }) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m15.75 8.39 6-3.464v14.148l-6-3.465v2.141H2.25V6.25h13.5v2.14zm0 1.733v3.754l4.5 2.599V7.524l-4.5 2.599zm-12 6.127v-8.5h10.5v8.5H3.75z"
        fill="#363AA1"
      />
    </svg>
  );
}
