import { SEARCH_DONE, SEARCH_INITIATED } from "./searchTypes";

export const searchInitiatedAction = () => (dispatch) => {
  dispatch({
    type: SEARCH_INITIATED,
  });
};

export const searchDoneAction = (data) => (dispatch) => {
  dispatch({
    type: SEARCH_DONE,
    payload: data,
  });
};
