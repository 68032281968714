import { useRef, Fragment, useState, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import CreditsIcon from '../icons/CreditsIcon';
import DateIcon from '../icons/DateIcon';
import LocationIcon from '../icons/LocationIcon';
import PrinterIcon from '../icons/PrinterIcon';
import CloseIcon from '../icons/CloseIcon';
import LocalAlert from '../alert/LocalAlert';
import {
  getFormattedDateInTz,
  getFormattedDateInTz2,
} from '../../utility/commonUtil';
import './attendeeBookingDetails.scss';
import Dialogue from '../dialogue/Dialogue';

export default function PurchaseDetails({
  bookingDetails,
  cancelTicket,
  showCancelButtons,
  setShowCancelButtons,
  setBookingDetails,
}) {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Booking Receipt',
  });

  const currency = bookingDetails?.orderCurrency?.toString().toUpperCase();

  const receiptComponent = (
    <div
      className="grid-table-wrap"
      style={{
        border: '.5px solid #ced9de',
        borderRadius: 4,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.08)',
        padding: '24px 4px 0px 4px',
      }}
    >
      <div className="px-20 mb-8">
        <h4 className="mb-24">Confemy Booking Details</h4>
        <div>
          <h4 className="mb-12">
            {bookingDetails?.media
              ? bookingDetails?.media?.title
              : 'Media title'}
          </h4>
          <div className="caption-2-regular-gray3 preview-text mb-16">
            <p className="mb-8">
              <span className="caption-1-heavy-cblack">Booking number : </span>
              <span>{bookingDetails?.serialNumber} </span>
            </p>
            <p>
              <span className="caption-1-heavy-cblack">Booking Date : </span>
              <span>
                {' '}
                {getFormattedDateInTz2(
                  bookingDetails?.bookingDate,
                  bookingDetails?.timezone
                )}
              </span>
            </p>
          </div>
          <div className="mb-12">
            <div className="flex-vc  mb-6">
              <CreditsIcon className="icon-sm mr-12" />
              <span className="caption-2-regular-gray3">
                {bookingDetails?.media?.credits?.length > 0
                  ? `${bookingDetails?.media?.credits[0]?.quantity} credits`
                  : 'Credits not offered'}
              </span>
            </div>
          </div>
          <p>
            <span className="caption-1-heavy-cblack">
              Total Booking Amount:{' '}
              {bookingDetails?.organiserAmount === 0
                ? 'FREE'
                : `${bookingDetails?.currency}-${bookingDetails?.organiserAmount}`}
            </span>
          </p>
        </div>
      </div>
      <div>
        <div
          className="p-20 mb-8 mt-8"
          style={{
            backgroundColor: showCancelButtons ? '#ecf0f2' : null,
          }}
        >
          <div>
            {bookingDetails?.mediaBooked === 4 ? (
              <span
                style={{ color: '#4cb944' }}
                className="caption-1-heavy-cblack"
              >
                Cancelled
              </span>
            ) : (
              showCancelButtons && (
                // <button
                //   className="small-button small-button-green"
                //   disabled={!showCancelButtons}
                //   onClick={() => {
                //     cancelTicket(bookingDetails);
                //   }}
                // >
                //   Cancel
                // </button>

                <Dialogue
                  msg="Are you sure you want to cancel your purchase?"
                  title="Confirm Cancel !!"
                  closeDialogue={() => {
                    setShowCancelButtons(false);
                  }}
                  yesAction={() => cancelTicket(bookingDetails)}
                />
              )
            )}
          </div>
          <div className="abd-grid-item mt-16">
            <p className="mb-4">
              <span className="caption-1-heavy-cblack">First Name: </span>
              <span className="caption-2-regular-gray3">
                {bookingDetails?.user?.firstName}
              </span>
            </p>
            <p className="mb-4">
              <span className="caption-1-heavy-cblack">Last Name: </span>
              <span className="caption-2-regular-gray3">
                {bookingDetails?.user?.lastName}
              </span>
            </p>
            <p className="mb-4">
              <span className="caption-1-heavy-cblack">Email: </span>
              <span className="caption-2-regular-gray3">
                {bookingDetails?.user?.email}
              </span>
            </p>
            {/* <p className="caption-1-heavy-cblack mb-4">Ticket Details</p> */}

            <p className="caption-2-regular-gray3 mb-4">
              <span> Price: </span>
              <span>
                {bookingDetails.organiserAmount > 0
                  ? `${bookingDetails?.currency} -`
                  : null}
              </span>
              <span>
                {' '}
                {bookingDetails?.organiserAmount > 0
                  ? bookingDetails?.organiserAmount
                  : 'FREE'}
              </span>
            </p>
            <p className="caption-2-regular-gray3 mb-4">
              <span>Service Charges: </span>
              <span>
                {bookingDetails.convFee > 0
                  ? `${bookingDetails?.currency} -  `
                  : null}
              </span>
              <span>{bookingDetails?.convFee?.toFixed(2)}</span>
            </p>
            {/* <p>
              <span className="caption-1-heavy-cblack">
                Total Ticket Price:{' '}
              </span>
              <span className="caption-2-regular-gray3">
                {guest.price > 0 ? `${currency} -` : null}
              </span>
              <span className="caption-2-regular-gray3">
                {' '}
                {guest.price > 0 ? guest.price : 'FREE'}
              </span>
            </p> */}
          </div>
          <div
            className="caption-2-regular-gray3"
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            {bookingDetails?.refunded && (
              <p className="caption-1-heavy-cblack mb-4">Refunded </p>
            )}
            {bookingDetails?.refunded && bookingDetails?.refundAmount && (
              <p>
                <span>{bookingDetails?.currency} </span>
                <span>{bookingDetails?.refundAmount}</span>
              </p>
            )}
            {bookingDetails?.refundStatus === 5 && <p>Refund request denied</p>}
          </div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    return () => setBookingDetails(null);
  }, []);
  return (
    <>
      <div style={{ justifyContent: 'flex-end' }} className="flex-vc pt-4">
        <div style={{ height: 60 }} className="flex-vc">
          <button
            className="small-button-text small-button-text-green"
            onClick={() => {
              handlePrint();
            }}
            disabled={showCancelButtons}
          >
            <div className="flex-vc">
              <i
                style={{ position: 'relative', paddingTop: 5 }}
                className="mr-8"
              >
                <PrinterIcon className="icon-sm" />
              </i>
              <span>Print</span>
            </div>
          </button>
          <div>
            {/* {showCancelButtons ? (
              <button
                style={{ width: 150 }}
                onClick={() => setShowCancelButtons(false)}
                className="small-button-outlined small-button-outlined-green"
              >
                Reset
              </button>
            ) : (
              
            )} */}
            <button
              style={{ width: 150 }}
              className="small-button-text small-button-text-green"
              onClick={() => setShowCancelButtons(true)}
            >
              <div className="flex-vc">
                <i
                  style={{ position: 'relative', paddingTop: 5 }}
                  className="mr-4"
                >
                  <CloseIcon className="icon-size" fill="#4cb944" />
                </i>
                <span>Cancel</span>
              </div>
            </button>
          </div>
        </div>
        <div style={{ display: 'none' }}>
          <div ref={componentRef} className="print-margins">
            {receiptComponent}
          </div>
        </div>
      </div>
      <LocalAlert />
      {receiptComponent}
    </>
  );
}
