import React, { Fragment, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { store, persistor } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import Footer from "./components/footer/Footer";
import HomePage from "./pages/home-page/HomePage";
import SigninPage from "./pages/signin-page/SigninPage";
import RegisterPage from "./pages/register-page/RegisterPage";
// import Alert from "./components/alert/Alert";
import CreateConfLandingPage from "./pages/create-conference-pages/CreateConfLandingPage";
import ConfBasicInfoPage from "./pages/create-conference-pages/ConfBasicInfoPage";
import ConfDetailsPage1 from "./pages/create-conference-pages/ConfDetailsPage1";
import ConfDetailsPage2 from "./pages/create-conference-pages/ConfDetailsPage2";
import ConfLiveStreamPage from "./pages/create-conference-pages/ConfLiveStreamPage";
import ConfTicketsPage from "./pages/create-conference-pages/ConfTicketsPage";
import ConfPreviewPage from "./pages/create-conference-pages/ConfPreviewPage";
import MyPrivateRoute from "./components/routing/MyPrivateRoute";
import CreateConfLayoutPage from "./pages/layout-pages/CreateConfLayoutPage";
import DashboardLayoutPage from "./pages/layout-pages/DashboardLayoutPage";
import CreateVideoCourseLayoutPage from "./pages/layout-pages/CreateVideoCourseLayoutPage";
import { loadUserAction } from "./redux/auth/authAction";
import { loadUserLocationAction } from "./redux/location/userLocationAction";
import CreateOrganizationPage from "./pages/organization-pages/CreateOrganizationPage";
import VerifyManagerPage from "./pages/verify-manager-page/VerifyManagerPage";
import MyOrganizationsPage from "./pages/organization-pages/MyOrganizationsPage";
import OrganizationDetailsPage from "./pages/organization-pages/OrganizationDetailsPage";
import SearchPage from "./pages/search-page/SearchPage";
import ConfDetailsPage from "./pages/conference-page/ConfDetailsPage";
import CreditRequestsPage from "./pages/credit-request-page/CreditRequestPage";
import TrackCreditPage from "./pages/track-credit-page/TrackCreditPage";
import ListConferencesPage from "./pages/track-credit-page/ListConferencesPage";
import MyConfsPage from "./pages/my-confs-page/MyConfsPage";
import Navbar from "./components/navbar/Navbar";
import BookingPage from "./pages/booking-page/BookingPage";
import UserCreditsPage from "./pages/attendee-profile-pages/UserCreditsPage";
import AccountSettingsPage from "./pages/attendee-profile-pages/AccountSettingsPage";
import UserProfileLayoutPage from "./pages/layout-pages/UserProfileLayoutPage";
import ForgotPasswordPage from "./pages/forgot-password-page/ForgotPasswordPage";
import ResetPasswordPage from "./pages/reset-password-page/ResetPasswordPage";
import PrintTicket from "./components/tickets/PrintTicket";
// import Receipt from "./components/tickets/Receipt";
import ReceiptPage from "./pages/attendee-profile-pages/ReceiptPage";
import ContactUsPage from "./pages/footer-pages/ContactUsPage";
import RefundPolicyPage from "./pages/footer-pages/RefundPolicyPage";
import AboutUsPage from "./pages/footer-pages/AboutUsPage";
import UserConfemyCreditTypePage from "./pages/user-confemy-credit-type/UserConfemyCreditTypePage";
import UserSpeakerPage from "./pages/user-speaker/UserSpeakerTablePage";
import MyConfDetailsPage from "./pages/conference-page/MyConfDetailsPage";
import PrivacyPolicyPage from "./pages/footer-pages/PrivacyPolicyPage";
import ConditionsPage from "./pages/footer-pages/ConditionsPage";
import HelpPage from "./pages/footer-pages/HelpPage";
import OrganizerDashboardPage from "./pages/organizer-dashboard-page/OrganizerDashboardPage";
import CheckoutPage from "./pages/booking-page/CheckoutPage";
import SavedConfsPage from "./pages/attendee-profile-pages/SavedConfsPage";
import PendingRefundsPage from "./pages/refund-pages/PendingRefundsPage";
import ProcessedRefundsPage from "./pages/refund-pages/ProcessedRefundsPage";
import BookingsForOrganizerPage from "./pages/booking-for-organizer-pages/BookingsForOrganizerPage";
import AttendeeBookingsPage from "./pages/attendee-profile-pages/AttendeeBookingsPage";

import AttendeeCreditsPage from "./pages/credit-request-page/AttendeeCreditsPage";

import FeedbackPage from "./pages/feedback-page/FeedbackPage";
import BdfoPage from "./pages/booking-for-organizer-pages/BdfoPage";
import EarningsPage from "./pages/earnings-page/EarningsPage";
import RefundDetailsPage from "./pages/refund-pages/RefundDetailsPage";
import ExternalCreditTypesPage from "./pages/attendee-profile-pages/ExternalCreditTypesPage";
import ConnectedAccountPage from "./pages/account-page/ConnectedAccountPage";
import StripePaymentStatusPage from "./pages/booking-page/StripePaymentStatusPage";
import FreeBookingStatusPage from "./pages/booking-page/FreeBookingStatusPage";
import LinkBankAccountPage from "./pages/link-bank-account-pages/LinkBankAccountPage";
import "./App.scss";

import SavedVideoCoursePage from "./pages/attendee-profile-pages/SavedMediaPage";

// create video course
import VideoCourseStep1Page from "./pages/create-video-course-pages/VideoCourseStep1Page";
import VideoCourseStep2Page from "./pages/create-video-course-pages/VideoCourseStep2Page";
import VideoCourseStep3Page from "./pages/create-video-course-pages/VideoCourseStep3Page";
import VideoCourseStep4Page from "./pages/create-video-course-pages/VideoCourseStep4Page";
import VideoCourseStep5Page from "./pages/create-video-course-pages/VideoCourseStep5Page";
import OrganiserVideoBookingPage from "./pages/organiser-video-booking-page/OrganiserVideoBookingPage";
import VideoCourseRefundsPage from "./pages/refund-pages/VideoCourseRefundPage";
import VideoCourseEarningsPage from "./pages/video-course-earning-page/VideoCourseEarningPage";
import VideoCourseCreditRequestsPage from "./pages/video-course-credit-page/VideoCourseCreditPage";
import VideoCourseCreditDetailsPage from "./pages/video-course-credit-page/VideoCourseCreditDetailsPage";
import BdfoVideoCoursePage from "./pages/booking-for-organizer-pages/BdfoVideoCoursePage";
import PreviewVideoCoursePage from "./pages/preview-video-course-page/PreviewVideoCoursePage";
import CreateVideoLandingPage from "./pages/create-video-course-pages/CreateVideoLandingPage";
import AttendeeDashboardLayoutPage from "./pages/layout-pages/AttendeeDashboardLayoutPage";
import AttendeeDashboardPage from "./pages/attendee-dashboard-page/AttendeeDashboardPage";
import ConfCreditsPage from "./pages/conf-credits-page/ConfCreditsPage";
import VideoCreditsPage from "./pages/video-credits-page/VideoCreditsPage";
import ExternalCreditsPage from "./pages/external-credits-page/ExternalCreditsPage";
import PurchaseHistoryPage from "./pages/purchase-history-page/PurchaseHistoryPage";
import PurchasedVideoPage from "./pages/attendee-video-pages/PurchasedVideoPage";
import MyAudioCoursePage from "./pages/audio-course-pages/MyAudioCoursePage";
import AudioCourseDetailsPage from "./pages/audio-course-pages/AudioCourseDetailsPage";
import PreviewAudioCoursePage from "./pages/audio-course-pages/PreviewAudioCoursePage";
import PurchaseAudioCoursePage from "./pages/audio-course-pages/PurchasedAudioCoursePage";
import SavedAudioCoursePage from "./pages/attendee-profile-pages/SavedAudioPage";
import AudioCourseBookingPage from "./pages/audio-course-pages/AudioCourseBookings";
import AudioCourseRefundsPage from "./pages/audio-course-pages/AudioCourseRefundPage";
import AudioCourseCreditRequestsPage from "./pages/audio-course-pages/AudioCourseCreditsPage";
import AudioCourseViewPage from "./pages/audio-course-pages/AudioCourseViewPage";
import AudioCourseAttendeeCreditsPage from "./pages/audio-course-pages/AudioCourseAttendeeCreditsPage";
import AudioCourseEarningsPage from "./pages/audio-course-pages/AudioCourseEarningPage";
import AudioCourseStep1Page from "./pages/create-audio-course-pages/AudioCourseStep1Page";
import CreateAudioCourseLayoutPage from "./pages/layout-pages/CreateAudioCourseLayoutPage";
import AudioCourseStep2Page from "./pages/create-audio-course-pages/AudioCourseStep2Page";
import AudioCourseStep3Page from "./pages/create-audio-course-pages/AudioCourseStep3Page";
import AudioCourseStep4Page from "./pages/create-audio-course-pages/AudioCourseStep4Page";
import AudioCourseStep5Page from "./pages/create-audio-course-pages/AudioCourseStep5Page";
import BdfoAudioCoursePage from "./pages/audio-course-pages/BdfoAudioCoursePage";
import CreatePodcastLayoutPage from "./pages/layout-pages/CreatePodcastLayoutPage";
import CreatePodcastStep1Page from "./pages/create-podcast-pages/CreatePodcastStep1Page";
import CreatePodcastStep2Page from "./pages/create-podcast-pages/CreatePodcastStep2Page";
import CreatePodcastStep3Page from "./pages/create-podcast-pages/CreatePodcastStep3Page";
import CreatePodcastStep4Page from "./pages/create-podcast-pages/CreatePodcastStep4Page";
import MyPodcastPage from "./pages/podcast-page/MyPodcastPage";
import AddPodcastEpisode from "./components/create-podcasts/AddPodcastEpisode";
import PodcastBookingPage from "./pages/podcast-page/PodcastBookingPage";
import PodcastEarningPage from "./pages/podcast-page/PodcastEarningPage";
import SavedPodcastPage from "./pages/attendee-profile-pages/SavedPodcastPage";
import PodcastCheckoutPage from "./pages/podcast-page/PodcastCheckoutPage";
import PodcastBookingPricePage from "./pages/podcast-page/PodcastBookingPricePage";
import PurchasedPodcastPage from "./pages/podcast-page/PurchasedPodcastsPage";
import VideoCourseDetailsPage from "./pages/course-details-page/VideoCourseDetailsPage";
import PodcastRefundsPage from "./pages/podcast-page/PodcastRefundPage";
import CourseBookingPage from "./pages/attendee-booking-pages/CourseBookingPage";
import PodcastDetailsPage from "./pages/podcast-page/PodcastDetailsPage";
import PodcastYearlyPrice from "./components/cards/PodcastYearlyPrice";
import PodcastMonthlyPrice from "./components/cards/PodcastMonthlyPrice";
import PodcastPreviewPage from "./pages/podcast-page/PodcastPreviewPage";
import PodcastYearlyCheckout from "./components/podcast/PodcastYearlyCheckout";
import PodcastMonthlyCheckout from "./components/podcast/PodcastMonthlyCheckout";
import ViewCoursePage from "./pages/view-course-page/ViewCoursePage";
import PodcastViewPage from "./pages/podcast-page/PodcastViewPage";
import MyVideoCoursePage from "./pages/video-course-pages/myVideoCoursePage";
import BdfoPodcastPage from "./pages/podcast-page/BdfoPodcastPage";
import PayoutPage from "./pages/payout-page/PayoutPage";

// import CourseBookingPage from "./pages/video-course-booking-page/CourseBookingPage";

//adding exact to false for conf detail route

const App = () => {
  console.log(window.innerWidth);
  useEffect(() => {
    store.dispatch(loadUserAction());
  }, []);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Fragment>
          <BrowserRouter>
            <Navbar />
            <Routes>
              <Route path="/" element={<HomePage />}></Route>
              <Route path="/test" element={<ViewCoursePage />}></Route>
              <Route path="/signin" element={<SigninPage />}></Route>
              <Route path="/register" element={<RegisterPage />}></Route>
              <Route
                path="/forgot-password"
                element={<ForgotPasswordPage />}
              ></Route>
              <Route path="/reset/:secret" element={<ResetPasswordPage />} />
              <Route path="/search-conference" element={<SearchPage />}></Route>
              <Route
                exact={false}
                path="/search-conference/:confId"
                element={<ConfDetailsPage />}
              ></Route>
              <Route
                path="/dashboard/refunds/:refundId"
                element={<RefundDetailsPage />}
              ></Route>

              {/* <Route
              path="user-profile"
              element={
                <MyPrivateRoute>
                  <UserProfileLayoutPage />
                </MyPrivateRoute>
              }
            >
              <Route path="tickets" element={<AttendeeBookingsPage />} />
              <Route path="saved-conference" element={<SavedConfsPage />} />
              <Route path="credits" element={<UserCreditsPage />} />

              <Route
                path="video-courses/saved"
                element={<SavedVideoCoursePage />}
              />
              <Route
                path="external-credit-type"
                element={<ExternalCreditTypesPage />}
              />
              <Route
                path="video-courses/my-courses"
                element={<MyVideoCoursesPage />}
              />

              <Route
                index={true}
                element={<Navigate to="./tickets" replace />}
              ></Route>
            </Route> */}
              <Route
                path="attendee-dashboard"
                element={
                  <MyPrivateRoute>
                    <AttendeeDashboardLayoutPage />
                  </MyPrivateRoute>
                }
              >
                <Route
                  path=""
                  element={
                    <Navigate to="/attendee-dashboard/dashboard" replace />
                  }
                ></Route>
                <Route
                  path="dashboard"
                  element={<AttendeeDashboardPage />}
                ></Route>
                <Route path="booked-confs" element={<AttendeeBookingsPage />} />
                <Route path="liked-confs" element={<SavedConfsPage />} />
                <Route path="conf-credits" element={<ConfCreditsPage />} />

                {/* <Route path="video-courses" element={<MyVideoCoursesPage />} /> */}
                <Route path="video-courses" element={<PurchasedVideoPage />} />
                <Route path="video-liked" element={<SavedVideoCoursePage />} />
                <Route path="liked-audios" element={<SavedAudioCoursePage />} />
                <Route path="liked-podcasts" element={<SavedPodcastPage />} />
                <Route path="video-credits" element={<VideoCreditsPage />} />
                <Route
                  path="audio-credits"
                  element={<AudioCourseAttendeeCreditsPage />}
                />
                <Route path="my-credits" element={<UserCreditsPage />} />
                <Route
                  path="purchase-history"
                  element={<PurchaseHistoryPage />}
                />
                <Route
                  path="external-credit-types"
                  element={<ExternalCreditTypesPage />}
                />
                <Route
                  path="external-credits"
                  element={<ExternalCreditsPage />}
                />
                <Route path="feedback" element={<FeedbackPage />}></Route>
                <Route
                  path="my-audio-courses"
                  element={<PurchaseAudioCoursePage />}
                ></Route>
                <Route
                  path="my-podcasts"
                  element={<PurchasedPodcastPage />}
                ></Route>
                {/* <Route
                index={true}
                element={<Navigate to="./tickets" replace />}
              ></Route> */}
              </Route>
              {/* End Attendee Dashboard */}
              <Route
                path="/print-ticket/:bookingId"
                element={
                  <MyPrivateRoute>
                    <PrintTicket />
                  </MyPrivateRoute>
                }
              />
              <Route
                path="/print-receipt/:bookingId"
                element={
                  <MyPrivateRoute>
                    <ReceiptPage />
                  </MyPrivateRoute>
                }
              />
              <Route path="track-credits" element={<TrackCreditPage />}></Route>

              <Route path="accounts" element={<ConnectedAccountPage />}></Route>

              <Route
                path="book-conference/:confId"
                element={
                  <MyPrivateRoute>
                    <BookingPage />
                  </MyPrivateRoute>
                }
              ></Route>
              <Route
                path="book-course/:courseId"
                element={
                  <MyPrivateRoute>
                    <CourseBookingPage />
                  </MyPrivateRoute>
                }
              ></Route>

              <Route
                path="account-settings"
                element={<AccountSettingsPage />}
              />
              <Route
                path="video-courses/:purchaseId"
                element={
                  <MyPrivateRoute>
                    <ViewCoursePage />
                  </MyPrivateRoute>
                }
              ></Route>
              <Route
                path="podcasts/:purchaseId"
                element={
                  <MyPrivateRoute>
                    <PodcastViewPage />
                  </MyPrivateRoute>
                }
              ></Route>

              <Route
                path="courses/:mediaId"
                element={<VideoCourseDetailsPage />}
              ></Route>
              <Route
                path="audio-courses/:mediaId"
                element={<AudioCourseDetailsPage />}
              ></Route>
              <Route path="podcast/:mediaId" element={<PodcastDetailsPage />}>
                {/* <Route path="" element={<PodcastYearlyPrice />}></Route> */}
                <Route
                  path=""
                  element={<Navigate to="monthly" replace />}
                ></Route>
                <Route path="yearly" element={<PodcastYearlyPrice />}></Route>
                <Route path="monthly" element={<PodcastMonthlyPrice />}></Route>
              </Route>

              <Route
                path="book-conference/checkout"
                element={
                  <MyPrivateRoute>
                    <CheckoutPage />
                  </MyPrivateRoute>
                }
              ></Route>
              <Route
                path="/book-podcast/checkout"
                element={
                  <MyPrivateRoute>
                    <PodcastCheckoutPage />
                  </MyPrivateRoute>
                }
              ></Route>
              <Route
                path="/payment-status/:confId"
                element={
                  <MyPrivateRoute>
                    <StripePaymentStatusPage />
                  </MyPrivateRoute>
                }
              ></Route>
              <Route
                path="/podcast/booking-price/:courseId"
                element={
                  <MyPrivateRoute>
                    <PodcastBookingPricePage />
                  </MyPrivateRoute>
                }
              >
                <Route
                  path=""
                  element={<Navigate to="monthly" replace />}
                ></Route>
                <Route
                  path="yearly"
                  element={<PodcastYearlyCheckout />}
                ></Route>
                <Route
                  path="monthly"
                  element={<PodcastMonthlyCheckout />}
                ></Route>
              </Route>
              <Route
                path="/free-booking-status"
                element={
                  <MyPrivateRoute>
                    <FreeBookingStatusPage />
                  </MyPrivateRoute>
                }
              ></Route>

              <Route
                path="dashboard"
                element={
                  <MyPrivateRoute>
                    <DashboardLayoutPage />
                  </MyPrivateRoute>
                }
              >
                <Route
                  path=""
                  element={<Navigate to="organizer-dashboard" replace />}
                ></Route>
                <Route
                  path="organizer-dashboard"
                  element={<OrganizerDashboardPage />}
                ></Route>

                <Route
                  path="create-conference"
                  element={<CreateConfLandingPage />}
                ></Route>
                <Route
                  path="create-video"
                  element={<CreateVideoLandingPage />}
                ></Route>
                <Route
                  path="my-video-courses"
                  element={<MyVideoCoursePage />}
                ></Route>

                <Route
                  path="my-audio-courses"
                  element={<MyAudioCoursePage />}
                ></Route>
                <Route
                  path="video-course-bookings"
                  element={<OrganiserVideoBookingPage />}
                ></Route>
                <Route
                  path="audio-course-bookings"
                  element={<AudioCourseBookingPage />}
                ></Route>
                <Route path="my-podcasts" element={<MyPodcastPage />}></Route>
                <Route
                  path="podcast/refunds"
                  element={<PodcastRefundsPage />}
                ></Route>
                <Route
                  path="podcast-bookings"
                  element={<PodcastBookingPage />}
                ></Route>

                <Route
                  path="add-pod-episode"
                  element={<AddPodcastEpisode />}
                ></Route>
                <Route path="create-conf" element={<CreateConfLayoutPage />}>
                  <Route
                    path=""
                    element={<Navigate to="step-1" replace />}
                  ></Route>
                  <Route path="step-1" element={<ConfBasicInfoPage />}></Route>
                  <Route path="step-2" element={<ConfDetailsPage1 />}></Route>
                  <Route path="step-3" element={<ConfDetailsPage2 />}></Route>
                  <Route path="step-4" element={<ConfLiveStreamPage />}></Route>
                  <Route path="step-5" element={<ConfTicketsPage />}></Route>
                  <Route path="step-6" element={<ConfPreviewPage />}></Route>
                </Route>
                <Route
                  path="create-vc"
                  element={<CreateVideoCourseLayoutPage />}
                >
                  <Route
                    path=""
                    element={<Navigate to="step-1" replace />}
                  ></Route>
                  <Route
                    path="step-1"
                    element={<VideoCourseStep1Page />}
                  ></Route>
                  <Route
                    path="step-2"
                    element={<VideoCourseStep2Page />}
                  ></Route>
                  <Route
                    path="step-3"
                    element={<VideoCourseStep3Page />}
                  ></Route>
                  <Route
                    path="step-4"
                    element={<VideoCourseStep4Page />}
                  ></Route>
                  <Route
                    path="step-5"
                    element={<VideoCourseStep5Page />}
                  ></Route>
                </Route>

                <Route
                  path="create-ac"
                  element={<CreateAudioCourseLayoutPage />}
                >
                  <Route
                    path=""
                    element={<Navigate to="step-1" replace />}
                  ></Route>
                  <Route
                    path="step-1"
                    element={<AudioCourseStep1Page />}
                  ></Route>
                  <Route
                    path="step-2"
                    element={<AudioCourseStep2Page />}
                  ></Route>
                  <Route
                    path="step-3"
                    element={<AudioCourseStep3Page />}
                  ></Route>
                  <Route
                    path="step-4"
                    element={<AudioCourseStep4Page />}
                  ></Route>
                  <Route
                    path="step-5"
                    element={<AudioCourseStep5Page />}
                  ></Route>
                </Route>
                <Route path="create-pod" element={<CreatePodcastLayoutPage />}>
                  <Route
                    path=""
                    element={<Navigate to="step-1" replace />}
                  ></Route>
                  <Route
                    path="step-1"
                    element={<CreatePodcastStep1Page />}
                  ></Route>
                  <Route
                    path="step-2"
                    element={<CreatePodcastStep2Page />}
                  ></Route>
                  <Route
                    path="step-3"
                    element={<CreatePodcastStep3Page />}
                  ></Route>
                  <Route
                    path="step-4"
                    element={<CreatePodcastStep4Page />}
                  ></Route>
                  /
                </Route>

                <Route
                  path="create-organization"
                  element={<CreateOrganizationPage />}
                ></Route>
                <Route
                  path="bookings"
                  element={<BookingsForOrganizerPage />}
                ></Route>
                <Route
                  path="refunds"
                  element={
                    <Navigate to="/dashboard/organizer-dashboard" replace />
                  }
                ></Route>
                <Route
                  path="conference/refunds"
                  element={<PendingRefundsPage />}
                ></Route>
                <Route
                  path="videoCourse/refunds"
                  element={<VideoCourseRefundsPage />}
                ></Route>
                <Route
                  path="audioCourse/refunds"
                  element={<AudioCourseRefundsPage />}
                ></Route>

                <Route
                  path="conferences/earnings"
                  element={<EarningsPage />}
                ></Route>
                <Route
                  path="videoCourses/earnings"
                  element={<VideoCourseEarningsPage />}
                ></Route>

                <Route
                  path="audioCourses/earnings"
                  element={<AudioCourseEarningsPage />}
                ></Route>
                <Route
                  path="podcast/earnings"
                  element={<PodcastEarningPage />}
                ></Route>
                <Route path="payout" element={<PayoutPage />}></Route>
                <Route
                  path="credit-requests"
                  element={<CreditRequestsPage />}
                ></Route>
                <Route
                  path="videoCourse/credit-requests"
                  element={<VideoCourseCreditRequestsPage />}
                ></Route>
                <Route
                  path="audioCourse/credit-requests"
                  element={<AudioCourseCreditRequestsPage />}
                ></Route>
                <Route
                  path="confemy-credit-type"
                  element={<UserConfemyCreditTypePage />}
                ></Route>
                <Route
                  path="user-speakers"
                  element={<UserSpeakerPage />}
                ></Route>
                <Route path="my-conferences" element={<MyConfsPage />}></Route>
                <Route
                  path="my-organizations"
                  element={<MyOrganizationsPage />}
                ></Route>

                <Route
                  path="my-organizations/:organizationId"
                  element={<OrganizationDetailsPage />}
                ></Route>
                <Route path="feedback" element={<FeedbackPage />}></Route>
              </Route>
              <Route path="/verify/:token" element={<VerifyManagerPage />} />
              <Route path="/help" element={<HelpPage />} />
              <Route path="/about" element={<AboutUsPage />} />
              <Route path="/contact-us" element={<ContactUsPage />} />
              <Route
                path="/terms-privacy-policy"
                element={<PrivacyPolicyPage />}
              />
              <Route path="/conditions" element={<ConditionsPage />} />
              <Route
                path="/cancellation-refund"
                element={<RefundPolicyPage />}
              />
              <Route
                path="/myconfs/:confId"
                element={
                  <MyPrivateRoute>
                    <MyConfDetailsPage />
                  </MyPrivateRoute>
                }
              />
              <Route
                path="/view/audio-courses/:purchaseId"
                element={
                  <MyPrivateRoute>
                    <AudioCourseViewPage />
                  </MyPrivateRoute>
                }
              >
                {/* <Route path="" element={<OverViewTabComponent />}></Route> */}
              </Route>
              <Route
                path="/bookings/:confId"
                element={
                  <MyPrivateRoute>
                    <BdfoPage />
                  </MyPrivateRoute>
                }
              />
              <Route
                path="/attendee-credits/:confId"
                element={
                  <MyPrivateRoute>
                    <AttendeeCreditsPage />
                  </MyPrivateRoute>
                }
              />
              <Route
                path="/videoCourse-credits/:confId"
                element={
                  <MyPrivateRoute>
                    <VideoCourseCreditDetailsPage />
                  </MyPrivateRoute>
                }
              />

              <Route
                path="/videoCourse/bookings/:videoCourseId"
                element={
                  <MyPrivateRoute>
                    <BdfoVideoCoursePage />
                  </MyPrivateRoute>
                }
              />
              <Route
                path="/audioCourse/bookings/:audioCourseId"
                element={
                  <MyPrivateRoute>
                    <BdfoAudioCoursePage />
                  </MyPrivateRoute>
                }
              />
              <Route
                path="/podcast/bookings/:podcastId"
                element={
                  <MyPrivateRoute>
                    <BdfoPodcastPage />
                  </MyPrivateRoute>
                }
              />
              <Route
                path="/videoCourse/preview/:mediaId"
                element={
                  <MyPrivateRoute>
                    <PreviewVideoCoursePage />
                  </MyPrivateRoute>
                }
              ></Route>
              <Route
                path="/podcast/preview/:mediaId"
                element={
                  <MyPrivateRoute>
                    <PodcastPreviewPage />
                  </MyPrivateRoute>
                }
              >
                <Route
                  path=""
                  element={<Navigate to="yearly" replace />}
                ></Route>
                <Route path="yearly" element={<PodcastYearlyPrice />}></Route>
                <Route path="monthly" element={<PodcastMonthlyPrice />}></Route>
              </Route>
              <Route
                path="/audioCourse/preview/:mediaId"
                element={
                  <MyPrivateRoute>
                    <PreviewAudioCoursePage />
                  </MyPrivateRoute>
                }
              />
              <Route
                path="/list-conferences"
                element={<ListConferencesPage />}
              ></Route>
            </Routes>
            <Footer />
          </BrowserRouter>
        </Fragment>
      </PersistGate>
    </Provider>
  );
};

export default App;
