import { useState, Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { alertAction } from '../../redux/alert/alertAction';
import LikeBlueIcon from '../icons/LikeBlueIcon';
import LikeInactiveIcon from '../icons/LikeInactiveIcon';
import playIcon from '../../assets/play.png';
import CreditsIcon from '../icons/CreditsIcon';
import {
  likeMedia,
  unLikeMedia,
  getPrice,
  openInNewTab,
} from '../../utility/commonUtil';
import { useNavigate } from 'react-router-dom';

export default function CourseDetailsCard({ media, playPreview }) {
  const [isLiked, setIsLiked] = useState(false);

  // const user = useSelector((state) => state.auth.user);
  const auth = useSelector((state) => state.auth);
  const { isAuthenticated, user } = auth;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const like = (confId, userId, setIsLiked, type) => {
    if (!isLiked && isAuthenticated && user) {
      likeMedia(confId, userId, setIsLiked, type);
    } else {
      dispatch(alertAction('Please login to like a course.', 'info'));
    }
  };

  const unlike = (confId, userId, setIsLiked, type) => {
    if (isLiked && isAuthenticated && user) {
      unLikeMedia(confId, userId, setIsLiked, type);
    } else {
      dispatch(alertAction('Please login to unlike a course.', 'info'));
    }
  };

  useEffect(() => {
    setIsLiked(media?.isLiked);
  }, [media?.isLiked]);

  return (
    <div className="course-details-card ">
      <div
        className="ce-banner-wrap position-relative mb-24"
        onClick={() => playPreview()}
        style={{ cursor: 'pointer' }}
      >
        {media?.banner?.map((item, indx) => (
          <img
            key={indx}
            className="ce-banner-img"
            alt="event banner"
            src={item?.Location}
            // Revoke data uri after image is loaded
            onLoad={() => {
              // URL.revokeObjectURL(image.Location);
            }}
          />
        ))}
        <div className="play-button-circle-wrap">
          <div className="play-button-circle">
            <img src={playIcon} className="icon-sm" />
          </div>
          <div className="play-preview-text">Preview the course</div>
        </div>
      </div>

      <div
        className="flex-vc caption-2-bold-cblack mb-4"
        style={{
          width: '100%',
          height: '100%',
          fontSize: 20,
          gap: '8px',
          wordBreak: 'break-word',
        }}
      >
        {getPrice(
          media?.currency,
          media?.actualPrice,
          media?.price,
          media?.currencySymbol
        )}
      </div>
      <div className="mb-16">
        {media?.discount && (
          <p className="avenir-heavy-16    color-primary">
            <span>{media?.discount}</span> % Off
          </p>
        )}
      </div>
      <div className="flex-vc">
        <button
          className="small-button small-button-green mr-10"
          style={{ flexGrow: 1, height: 54 }}
          onClick={() => {
            if (media?.tag === 'Video Course' || 'Audio Course') {
              navigate(`/book-course/${media?._id}`);
            } else if (media?.tag === 'Podcast')
              navigate(`/podcast/booking-price/${media?._id}`);
            else {
              navigate(`/book-conference/${media?._id}`);
            }
          }}
        >
          Book
        </button>

        <span className="primary-color-box " style={{ cursor: 'pointer' }}>
          {isLiked ? (
            <i
              className="conference-card-buttons"
              onClick={() =>
                unlike(media?._id, user?._id, setIsLiked, media?.type)
              }
            >
              {/* <LikeRedIcon className="icon-sm" /> */}
              <LikeBlueIcon className="icon-lg" />
            </i>
          ) : (
            <i
              className="conference-card-buttons"
              onClick={() =>
                like(media?._id, user?._id, setIsLiked, media?.type)
              }
            >
              <LikeInactiveIcon className="icon-lg" />
            </i>
          )}
        </span>
        {/* <LikeInactiveIcon className="icon-size" /> */}
      </div>
      <div className="cep-card-bottombox">
        <div className="mb-8">
          <div className="flex-vc mb-8">
            <CreditsIcon className="icon-xs mr-8" />
            <p className="caption-2-regular-gray3">Credits</p>
          </div>
          <div className="cc-hovcard-credits" style={{ padding: 0 }}>
            {media?.credits?.length > 0 ? (
              media?.credits.map((credit, indx) => {
                return (
                  <Fragment key={indx}>
                    <div className="caption-2-regular-gray3 line-clamp">
                      {/* AMA Category I credits */}
                      {credit.creditId.name}
                    </div>
                    <div className="caption-2-regular-gray3">
                      {credit.quantity}
                    </div>
                  </Fragment>
                );
              })
            ) : (
              <div className="caption-2-regular-gray3">Credits not added</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
